import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';

const TokenVerification = () => {
  const navigate = useNavigate();
  const getUserInfoFromLocalStorage = JSON.parse(localStorage.getItem('loginInfo')) || {};

  useEffect(() => {
    if (getUserInfoFromLocalStorage?.loggedIn) {
      getVerifyToken();

      const refreshTokenInterval = setInterval(() => {
        getVerifyToken();
      }, 600000); // 10 minutes

      return () => {
        clearInterval(refreshTokenInterval);
      };
    }
     // eslint-disable-next-line
  }, []);

  const getVerifyToken = () => {
    const config = {
      headers: {
        'ngrok-skip-browser-warning': 'true',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getUserInfoFromLocalStorage?.token}`,
      },
    };
  
    axios.get(`https://hanaka-backend.onrender.com/api/refreshToken`, config)
      .then((refreshResponse) => {
        let userInfo = {
          token: refreshResponse?.data?.token,
          user: refreshResponse?.data?.data,
          loggedIn: true,
      }
      localStorage.setItem('loginInfo', JSON.stringify(userInfo));
      })
      .catch((error) => {
        console.log(error)
        if(error) {
          navigate('/')
          localStorage.removeItem('loginInfo'); 
        }
      });
  };

  return null; // This component does not render anything
};

export default TokenVerification;
