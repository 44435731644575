import axios from 'axios';
import { setLoadingData, setDataError, setSuccess, setErrorTimeOut, setLoadingDataForToken, setVerifyModal, setSuccessTimeOut } from './Verify'


export const postVerifyUser = (userDetail) => (dispatch) => {
  dispatch(setLoadingData())
  const config = {
    headers: {
      'ngrok-skip-browser-warning': 'true', //Remove this when you use the hosted server
      'Content-Type': 'application/json',
    },
  };

  axios
    .post(`https://hanaka-backend.onrender.com/api/generateToken`, userDetail, config)
    .then((response) => {
        // let userInfo = {
        //     token: response?.data?.token,
        //     user: response?.data?.data
        // }
        // console.log(response)
        dispatch(setSuccess(response?.data?.message))
        setTimeout(() => {
            dispatch(setSuccessTimeOut());
          }, 13000); 
    //   localStorage.setItem('serverResponse', JSON.stringify(response.data.data))
    //   localStorage.setItem('loginInfo', JSON.stringify(userInfo));
    })
    .catch((error) => {
        dispatch(setDataError(error.response?.data?.message))
        setTimeout(() => {
            dispatch(setErrorTimeOut());
          }, 4000);
    })
}

export const postVerifyToken = (userDetail, tokenFromLocal) => (dispatch) => {
  dispatch(setLoadingDataForToken());
  const config = {
    headers: {
      'ngrok-skip-browser-warning': 'true',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${tokenFromLocal}`,
    },
  };

  axios
    .post(`https://hanaka-backend.onrender.com/api/verifyUserCredentials`, userDetail, config)
    .then((response) => {
      // console.log(response?.data?.data);
      dispatch(setSuccess(response?.data?.message));
      setTimeout(() => {
        dispatch(setSuccessTimeOut());
      }, 5000);

      // Here I chained the first and second api 
      return axios.get(`https://hanaka-backend.onrender.com/api/refreshToken`, config);
    })
    .then((refreshResponse) => {
      let userInfo = {
        token: refreshResponse?.data?.token,
        user: refreshResponse?.data?.data,
        loggedIn: true,
    }
    localStorage.setItem('loginInfo', JSON.stringify(userInfo));
    dispatch(setVerifyModal())
    })
    .catch((error) => {
      console.log(error);
      dispatch(setDataError(error.response.data.message));
      setTimeout(() => {
        dispatch(setErrorTimeOut());
      }, 4000);
    });
};
