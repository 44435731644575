import React from 'react';

const ImageModal = ({ imageUrl, closeModal }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="bg-black opacity-75 inset-0 fixed" onClick={closeModal}></div>
      <div className="bg-white rounded-lg shadow-lg p-4 max-w-screen-sm mx-auto z-10">
        <img src={imageUrl} alt="Vendor's avatar" className="w-full max-h-64 h-auto" />
        <div className="mt-4 text-center">
          <button
            onClick={closeModal}
            className="bg-gray-300 text-gray-700 px-3 py-1 rounded hover:bg-gray-400"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default ImageModal;

