export const ActionTypes = {
    SEARCH_FETCH_DATA_LOADING: 'SEARCH_FETCH_DATA_LOADING',
    SEARCH_FETCH_DATA_ERROR: 'SEARCH_FETCH_DATA_ERROR',
    SEARCH_SUCCESS: 'SEARCH_SUCCESS',
    SUCCESS_MESSAGE_TIMEOUT: 'SUCCESS_MESSAGE_TIMEOUT',
    SUCCESS_MESSAGE: 'SUCCESS_MESSAGE',
    SEARCH_ERROR_TIMEOUT: 'SEARCH_ERROR_TIMEOUT',
  }

  export const setSearchLoadingData = () => ({
    type: ActionTypes.SEARCH_FETCH_DATA_LOADING,
  })
  
  export const setDataError = (data) => ({
    type: ActionTypes.SEARCH_FETCH_DATA_ERROR,
    payload: data,
  })

  export const setSearchSuccess = (data) => ({
    type: ActionTypes.SEARCH_SUCCESS,
    payload: data,
  })

  export const setSuccessMessage = () => ({
    type: ActionTypes.SUCCESS_MESSAGE,
  })
  export const setSuccessMessageTimeOut = () => ({
    type: ActionTypes.SUCCESS_MESSAGE_TIMEOUT,
  })

  export const setErrorTimeOut = () => ({
    type: ActionTypes.SEARCH_ERROR_TIMEOUT,
  })

  const initialState = {
    searchLoading: false,
    searchingError: '',
    success: [],
    successMessage: false,
  }
  
  const searchReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case ActionTypes.SEARCH_FETCH_DATA_LOADING:
        return {
          ...state,
          searchLoading: true,
        }
      case ActionTypes.SEARCH_FETCH_DATA_ERROR:
        return {
          ...state,
          searchingError: payload,
          searchLoading: false,
          success: false,
        }
      case ActionTypes.SEARCH_SUCCESS:
        return {
          ...state,
          success: payload,
          searchLoading: false,
          searchingError: '',
        }
        case ActionTypes.SUCCESS_MESSAGE:
            return {
              ...state,
              successMessage: true,
        }
        case ActionTypes.SUCCESS_MESSAGE_TIMEOUT:
            return {
              ...state,
              successMessage: false,
        }
        case ActionTypes.SEARCH_ERROR_TIMEOUT:
            return {
              ...state,
              searchingError: '',
        }
      default:
        return state
    }
  }
  export default searchReducer