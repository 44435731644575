import axios from 'axios';
import { setSearchLoadingData, setDataError, setSearchSuccess, setSuccessMessage, setSuccessMessageTimeOut, setErrorTimeOut, } from './Search'

export const postSearchOccupation = (userSearch) => (dispatch) => {
    dispatch(setSearchLoadingData());
    const config = {
      headers: {
        'ngrok-skip-browser-warning': 'true',
        'Content-Type': 'application/json',
      },
    };

    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        function(position) {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
  
          const locationDetails = {
            latitude,
            longitude,
          };

          axios
            .post(`https://hanaka-backend.onrender.com/api/getAllOccupationByLocation/${userSearch}`, locationDetails, config)
            .then((response) => {
              dispatch(setSearchSuccess(response?.data?.data ? response?.data?.data : response?.data?.message));
              dispatch(setSuccessMessage())
              setTimeout(() => {
                dispatch(setSuccessMessageTimeOut());
              }, 4000);
            })
            .catch((error) => {
              dispatch(setDataError(error.response?.data?.message));
              setTimeout(() => {
                dispatch(setErrorTimeOut());
              }, 4000);
            });
        },
        function(err) {
          // Handle error, e.g., setError(err.message);
        },
        {
          enableHighAccuracy: true,
          timeout: 20000,
          maximumAge: 60000,
        }
      );
    }
  };
  