import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { CirclesWithBar } from 'react-loader-spinner';
import './Settings.css';

const SettingsEditForm = ({ editFunctionProps }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageTobeEdited, setSelectedImageTobeEdited] = useState(null);
  const [getUser, setGetUser] = useState();
  const [email, setEmail] = useState(null);
  const [userName, setUserName] = useState(null);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isDataSuccess, setIsDataSuccess] = useState(false);
  const [isUserEdit, setisUserEdit] = useState(false);

  const getUserInfoFromLocalStorage = JSON.parse(localStorage.getItem('loginInfo')) || {};

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedImageTobeEdited(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const editUser = async (e) => {
    e.preventDefault();
    if(isUserEdit) {
        return;
    }
    else
    setIsDataLoading(true);
    const formData = new FormData();

    if (selectedImageTobeEdited) {
      formData.append('image', selectedImageTobeEdited);
    }

    formData.append('userName', userName);

    // Check if the email is in edit mode before adding it to formData
    if (isUserEdit) {
      formData.append('email', email);
    }

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${getUserInfoFromLocalStorage.token}`,
      },
    };

    await axios.put('https://hanaka-backend.onrender.com/api/updateProfile', formData, config)
      .then(response => {
        setIsDataSuccess(true);
        setTimeout(() => setIsDataSuccess(false), 3000);
        setIsDataLoading(false);
      })
      .catch(error => {
        setIsError(true);
        setTimeout(() => setIsError(false), 5000);
        setIsDataLoading(false);
      });
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get('https://hanaka-backend.onrender.com/api/getUser', {
          headers: {
            'ngrok-skip-browser-warning': 'true', //Remove this when you use the hosted server
            'Authorization': `Bearer ${getUserInfoFromLocalStorage.token}`,
          },
        });
        const userDetail = response?.data.data;

        setEmail(userDetail.email);
        setUserName(userDetail.userName);
        setGetUser(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
        //If error fire logout function
      }
    }

    fetchData();
    // eslint-disable-next-line
  }, []);


  return (
    <div>
      <div className='hidden md:flex lg:items-center lg:px-6 lg:mt-8 lg:h-12 border-b-2 border-gray-300 lg:bg-white'>
        <p className='text-base settings_color text-white'>
          Edit Profile
        </p>
      </div>
      <form className="bg-slate-50 h-5/6 shadow-md rounded px-8 pt-6 pb-8 lg:bg-white" onSubmit={(e) => editUser(e)}>
        <i className="fa fa-long-arrow-left text-black lg:hidden hover:cursor-pointer" aria-hidden="true" onClick={() => editFunctionProps(false)}></i>
        <div className="text-center">
          <label htmlFor="avatar" className="cursor-pointer">
            <div className="relative inline-block">
              <img
                className="rounded-full w-20 h-20 md:w-32 md:h-32 object-cover cursor-pointer bg-slate-300"
                src={selectedImage ? selectedImage : getUser?.data.profileImage}
                alt="User Avatar"
              />
              {
                isUserEdit && <p className='text-xs md:text-sm'>Click on image to edit it</p>
              }
            </div>
          </label>
          <input
            id="avatar"
            type="file"
            accept="image/*"
            className="hidden"
            onChange={handleImageChange}
          />
        </div>

        <div className="mb-3">
          <label className="block text-gray-700 text-sm lg:text-sm md:text-lg font-bold lg:font-medium mb-2" htmlFor="full_name">
            Full Name
          </label>
          {isUserEdit ? (
            <input
              className="shadow appearance-none border rounded w-full py-2 md:py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="userName"
              type="text"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
            />
          ) : (
            <p>{userName}</p>
          )}
        </div>
        <div className="mb-3">
          <label className="block text-gray-700 text-sm md:text-lg lg:text-sm font-bold lg:font-medium mb-2" htmlFor="location">
            Email
          </label>
          {isUserEdit ? (
            <input
              className="shadow appearance-none border rounded w-full py-2 md:py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="location"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          ) : (
            <p>{email}</p>
          )}
        </div>
        <div className="flex items-center justify-between">
          <button
            className={`categoryButton text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-28`}
            type="submit"
            disabled={isDataLoading}
            onClick={() => setisUserEdit(!isUserEdit)}
          >
            {isDataLoading ? (
              <CirclesWithBar
                height="25"
                width="100"
                color="#0b1f3f"
                wrapperStyle={{}}
                visible={true}
                outerCircleColor=""
                innerCircleColor=""
                barColor=""
                ariaLabel='circles-with-bar-loading'
              />
            ) :
            isUserEdit ? "Submit" : "Edit"
        }
          </button>
        </div>
        {isDataSuccess &&
          <div className='flex items-center'>
            <p className='text-green-500'> User Information updated successfully</p>
            <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8 text-green-500" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path className="animate-draw" d="M5 13l4 4L19 7" />
            </svg>
          </div>
        }
        {isError &&
          <div className='flex items-center'>
            <p className='text-red-500'>Error Updating Information. Please try again.</p>
            <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8 text-red-500" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path className="animate-x" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </div>
        }
      </form>
    </div>
  );
};

export default SettingsEditForm;
