import React, { useState,  } from "react";
import styled from "styled-components";
import { AiOutlineSearch } from "react-icons/ai"
import { useDispatch, useSelector } from 'react-redux';
import { postSearchOccupation } from "../Redux/SearchApi";

const Hero = () =>{
    const dispatch = useDispatch()
    const [search, setSearch] = useState('')
    const [searchError, setSearchError] = useState('')

    const {
        searchLoading,
        searchingError,
        success,
        successMessage,
      } = useSelector((state) => state.searchReducer)

    const handleSearch = (e) => {
      e.preventDefault()
        if(search.length === 0) {
            setSearchError("Search input field cannot be empty")
            return setTimeout(() => {
                setSearchError('');
            }, 5000);
        }
        dispatch(postSearchOccupation(search))
    }

    return(
        <Container>
            <Wrapper>
                <Text>Find the best craftsmen in your area.</Text>
                <form onSubmit={(e) => handleSearch(e)} className="flex justify-center w-full">

                <InputWrap>
                    <Input placeholder="I am looking for..." onChange={(e) => setSearch(e.target.value)}/>
                    <AiOutlineSearch className="hover:cursor-pointer" style={{fontSize: "30px", color: "#5A018E"}} />
                </InputWrap>
                </form>
                {
                    searchError.length > 0 &&
                    <p className="text-red-400 text-xs md:text-sm mt-1">{searchError}</p>
                }
                {searchLoading ? (
                <p className="text-white flex items-center text-xs md:text-base">
                    Searching, please wait
                    <svg
                    className="animate-spin h-5 w-5 ml-2"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    >
                    <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                    ></circle>
                    <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8v-2"
                    ></path>
                    </svg>
                </p>
        ) : (
          ""
        )}
      { successMessage && Array.isArray(success) && (
        <div className='flex items-center justify-center mt-2'>
          <p className='text-white text-xs md:text-sm'>{`${search.charAt(0).toUpperCase() + search.slice(1)} found kindly check below`}</p>
          <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 text-white" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path className="animate-draw" d="M5 13l4 4L19 7" />
          </svg>
        </div>
      )}
      {success?.length > 0 && typeof(success) === 'string' && successMessage && (
        <div className='flex items-center justify-center mt-2'>
          <p className='text-white text-xs md:text-sm'>{`${success}`}</p>
          <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8 text-white" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path className="animate-x" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </div>
      )}
        {searchingError &&
            <div className='flex items-center'>
                <p className='text-red-500'>{searchingError}</p>
                <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8 text-red-500" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path className="animate-x" d="M6 18L18 6M6 6l12 12" />
                </svg>
             </div>
          }
            </Wrapper>
        </Container>
    )
};

export default Hero;

const Container = styled.div`
width: 100%;
height: 60vh;
display: flex;
justify-content: center;
align-items: center;
/* background-color: rgb(90, 1, 142); */
background-color: #C8A2C8;
`;
const Wrapper = styled.div`
width: 90%;
height: 90%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`;

const Text = styled.div`
color:  white;
/* font-family: poppins; */
font-size: 20px;

@media Screen and (max-width: 768px){
    font-size: 15px;

}
`;
const InputWrap = styled.div`
width: 40%;
background-color: white;
display: flex;
justify-content: space-between;
align-items: center;
height: 50px;
border-radius: 5px;
padding: 5px 15px 5px 20px;
margin-top: 30px;

@media Screen and (max-width: 768px){
    width: 70%;
}
@media Screen and (max-width: 525px){
    width: 80%;
}

`;
const Input = styled.input`
border: none;
outline: none;
width: 80%;
`;
