import React, { useRef, useEffect, useState } from "react";
import style from "styled-components"
import { FiPlus, } from "react-icons/fi";
import axios from 'axios';
import { CirclesWithBar } from 'react-loader-spinner';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';

import states from './States.json';


const UploadPage = () => {
    const [categories, setGetCategories] = useState([]);
    const [isCategoryDisabled, setIsCategoryDisabled] = useState(false);
    const [isStateDisabled, setIsStateDisabled] = useState(false);
    const [isLocalGogernmentDisabled, setIsLocalGogernmentDisabled] = useState(false);
    const [isSexDisabled, setIsSexDisabled] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [selectedLocalGovernment, setSelectedLocalGovernment] = useState('');
    const [description, setDescription] = useState('');
    const [occupation, setOccupation] = useState('');
    const [address, setAddress] = useState('');
    const [selectedSex, setSelectedSex] = useState('');
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [selectedFilesCount, setSelectedFilesCount] = useState(0);
    const [isDataSuccess, setIsDataSuccess] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [getOneUpload, setGetOneUpload] = useState("");
    const [editOccupation, setEditOccupation] = useState("");
    const [editAddress, setEditAddress] = useState("");
    const [editState, setEditState] = useState("");
    const [editLocalGovernment, setEditLocalGovernment] = useState("");
    const [editCategory, setEditCategory] = useState("");
    const [editSex, setEditSex] = useState("");
    const [editDescription, setEditDescription] = useState("");
    const [imageError, setImageError] = useState("");

    const fileInputRef = useRef(null);
    const navigate = useNavigate()

    const getUserInfoFromLocalStorage = JSON.parse(localStorage.getItem('loginInfo')) || {};

    const {
        verifySuccess,
      } = useSelector((state) => state.verifyReducer)

    const handleIconClick = () => {
        // Simulate a click on the hidden file input
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const newSelectedFiles = Array.from(event.target.files);
        // Append the new files to the existing selectedFiles array
        setSelectedFiles([...selectedFiles, ...newSelectedFiles]);
        setSelectedFilesCount(selectedFiles.length + newSelectedFiles.length);
    };
    
    const handleGetCategories = async () => {
        const config = {
            headers: {
              'ngrok-skip-browser-warning': 'true', //Remove this when you use the hosted server
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getUserInfoFromLocalStorage?.token}`,
            },
          };
    
        let getAllCategories = await axios.get('https://hanaka-backend.onrender.com/api/', config);
        setGetCategories(getAllCategories?.data?.data)
    };

    const handleGetOneUpload= async () => {
        const config = {
            headers: {
                'ngrok-skip-browser-warning': 'true', //Remove this when you use the hosted server
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getUserInfoFromLocalStorage?.token}`,
            },
        };
    
        let getAllUploads = await axios.get(`https://hanaka-backend.onrender.com/api/getOneUpload/${getUserInfoFromLocalStorage?.user?._id}`, config);//Id here is the id of the vendor
        setGetOneUpload(getAllUploads?.data?.data)
        // setEditOccupation(getAllUploads?.data?.data?.occupation)
        setEditAddress(getAllUploads?.data?.data?.address)
        setEditState(getAllUploads?.data?.data?.state)
        setEditLocalGovernment(getAllUploads?.data?.data?.location)
        setEditCategory(getAllUploads?.data?.data?.category)
        setEditSex(getAllUploads?.data?.data?.sex)
        setEditDescription(getAllUploads?.data?.data?.description)
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsDataLoading(true);
        const formData = new FormData();
        let errorOccurred = false;

        const maxSizeBytes = 50 * 1024 * 1024; // 50MB in bytes
        const maxImageCount = 4;

        if (selectedFiles) {
            let totalSize = 0; // Variable to keep track of the total size
            let imageCount = 0; // Variable to keep track of the number of images

            selectedFiles.forEach((file) => {
                if (imageCount < maxImageCount && totalSize + file.size <= maxSizeBytes) {
                formData.append(`images`, file);
                totalSize += file.size;
                imageCount++;
                } else {
                // Handle the case where the file count or size limit is exceeded
                setImageError("Image should not be more than four(4) or more than Fifty(50)MB");
                setTimeout(() => setImageError(""), 15000);
                errorOccurred = true;
                return;
                // console.error('Image upload limit exceeded.');
                }
            })
        }
        
        if (errorOccurred) {
            // The error condition is met, so we return here to exit the function
            return;
        }

        formData.append('state', selectedState);
        formData.append('categoryId', selectedCategory);
        formData.append('location', selectedLocalGovernment);
        formData.append('sex', selectedSex);
        formData.append('description', description);
        formData.append('occupation', occupation);
        formData.append('address', address);

        const config = {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${getUserInfoFromLocalStorage?.token}`,
            },
          };

          await axios.post('https://hanaka-backend.onrender.com/api/upload', formData, config)
            .then(response => {
              setIsDataSuccess(true);
              setTimeout(() => setIsDataSuccess(false), 3000);
              setIsDataLoading(false);
            })
            .catch(error => {
              setIsError(true);
              setTimeout(() => setIsError(false), 5000);
              setIsDataLoading(false);
            });
}

const nigerianStates = Object.keys(states)

let categoryToEdit = categories?.filter((category) => category._id === getOneUpload?.category)
let occupationFromCategory = categories?.filter((category) => category._id === editCategory)
let occupationFromCategoryToCreate = categories?.filter((category) => category._id === selectedCategory)

useEffect(() => {
    const getCategoryandUploadInformation = async() => {
        await handleGetCategories()
        await handleGetOneUpload()
    }
    getCategoryandUploadInformation()
    window.scrollTo(0, 0);
    if(getUserInfoFromLocalStorage?.token === undefined) {
        return navigate('/');
      }
    // eslint-disable-next-line
},[]);

const firstOccupationFromCategoryToCreate = occupationFromCategoryToCreate[0]?.categoryName;
const firstOccupationFromCategory = occupationFromCategory[0]?.categoryName;

useEffect(() => {
    if (firstOccupationFromCategoryToCreate) {
        setOccupation(firstOccupationFromCategoryToCreate.trim());
    } else if (firstOccupationFromCategory) {
        setEditOccupation(firstOccupationFromCategory.trim());
    }
}, [firstOccupationFromCategoryToCreate, firstOccupationFromCategory]);

    const handleEditSubmit = async(e) => {
        e.preventDefault();
        setIsDataLoading(true);
        const formData = new FormData();
        let errorOccurred = false;
        const maxSizeBytes = 50 * 1024 * 1024; // 50MB in bytes
        const maxImageCount = 4;

        if (selectedFiles) {
            let totalSize = 0; // Variable to keep track of the total size
            let imageCount = 0; // Variable to keep track of the number of images

            selectedFiles.forEach((file) => {
                if (imageCount < maxImageCount && totalSize + file.size <= maxSizeBytes) {
                formData.append(`images`, file);
                totalSize += file.size;
                imageCount++;
                } else {
                // Handle the case where the file count or size limit is exceeded
                setImageError("Image should not be more than four(4) or more than Fifty(50)MB");
                setTimeout(() => setImageError(""), 15000);
                setIsDataLoading(false);
                errorOccurred = true;
                return;
                }
            })
        }

        if (errorOccurred) {
            // The error condition is met, so we return here to exit the function
            return;
        }

        formData.append('state', editState);
        formData.append('categoryId', editCategory);
        formData.append('location', editLocalGovernment);
        formData.append('sex', editSex);
        formData.append('description', editDescription);
        formData.append('occupation', editOccupation);
        formData.append('address', editAddress);

        const config = {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${getUserInfoFromLocalStorage?.token}`,
            },
          };

          await axios.put('https://hanaka-backend.onrender.com/api/uploadEdit', formData, config)
            .then(response => {
              setIsDataSuccess(true);
              setTimeout(() => setIsDataSuccess(false), 4000);
              setIsDataLoading(false);
            })
            .catch(error => {
              setIsError(true);
              setTimeout(() => setIsError(false), 5000);
              setIsDataLoading(false);
            })
    }

  return <Container>
    {
       getOneUpload ?
        <Wrapper>
        <Top>
            <Title>Edit Service</Title>
        </Top>
        <Form onSubmit={(e) => handleEditSubmit(e)}>
            <Select
                onChange={(e) => {
                    setIsCategoryDisabled(true);
                    setEditCategory(e.target.value);
                }}
            >
                <Option disabled={isCategoryDisabled} >{categoryToEdit[0]?.categoryName}</Option>
                {
                    categories
                        // ?.filter(category => editCategory !== category._id)
                        ?.map(category => 
                            category.categoryName === 'Artisans' 
                                ? null
                                : <Option key={category._id} value={category._id}>{category.categoryName}</Option>
                        )
                }
            </Select>
            <Input className="hidden" value={occupationFromCategory[0]?.categoryName} onChange={(e) => setEditOccupation(occupationFromCategory[0]?.categoryName)} disabled placeholder="Occupation"/>
            <Input value={editAddress} onChange={(e) => setEditAddress(e.target.value)} placeholder="Address"/>
            <div className="flex w-4/5 mt-4 gap-x-2 items-center justify-center">
                <SelectResidence
                    onChange={(e) => {
                        setIsStateDisabled(true);
                        setEditState(e.target.value)
                    }}
                >
                    <Option disabled={isStateDisabled} value={editState}>{editState}</Option>
                    {
                        nigerianStates?.map((state, index) => 
                            <Option key={index} value={state}>{state}</Option>
                        )
                    }
                </SelectResidence>
                <SelectResidence
                    onChange={(e) => {
                        setIsLocalGogernmentDisabled(true);
                        setEditLocalGovernment(e.target.value);
                    }}
                >
                    <Option value={editLocalGovernment}>{editLocalGovernment}</Option>
                    {
                        states[editState]?.map((state, index) => 
                            <Option key={index} value={state}>{state}</Option>
                        )
                    }
                </SelectResidence>
            </div>

            <Select
                onChange={(e) => {
                    setIsSexDisabled(true);
                    setEditSex(e.target.value);
                }}
                className="mt-4"
            >
                <Option disabled={isSexDisabled} value={editSex}>{editSex}</Option>
                    <Option  value={"Male"}>{"Female"}</Option>
                    <Option  value={"Male"}>{"Male"}</Option>
            </Select>
            <Text>
                <span>Description</span>
                <div>Please enter a short description about yourself and your services below</div>
            </Text>
            <TextArea value={editDescription} onChange={(e) => setEditDescription(e.target.value)}></TextArea>
            <Text>
                <span>Edit Photo</span>
                <div>First image is always the title image. You have an upload limit of 4 images.</div>
            </Text>
            <div className="flex w-4/5 items-center lg:w-1/2" >
                <PictureInputSection>
                    <PhotoUploadWrap onClick={handleIconClick}>
                        <FiPlus color="#9302E1"/>
                        <UploadFile type="file"accept="image/*" ref={fileInputRef} style={{ display: "none" }} multiple onChange={handleFileChange}/>
                    </PhotoUploadWrap>
                {selectedFilesCount > 0 ? (
                    <div className="">
                        <span>{selectedFilesCount} {selectedFilesCount > 1 ? "images" : "image"} selected</span>
                    </div>
                    ) : (
                    <div className="">
                        <span>No image selected</span>
                        </div>
                )}
                </PictureInputSection>
            </div>
            <Button type="submit">
                {isDataLoading ? (
                <CirclesWithBar
                    // className="justify-center "
                    height="35"
                    width="50"
                    color="#0b1f3f"
                    wrapperStyle={{"justifyContent": 'space-around'}}
                    visible={true}
                    outerCircleColor=""
                    innerCircleColor=""
                    barColor=""
                    ariaLabel='circles-with-bar-loading'
                />
                ) :
                    "Continue"
                }  
            </Button>
            {isDataSuccess &&
          <div className='flex items-center'>
            <p className='text-green-500'> Successfully uploaded Information</p>
            <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8 text-green-500" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path className="animate-draw" d="M5 13l4 4L19 7" />
            </svg>
          </div>
        }
        {isError &&
          <div className='flex items-center'>
            <p className='text-red-500'>Error editing upload. Please try again.</p>
            <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8 text-red-500" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path className="animate-x" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </div>
        }
        {imageError &&
          <div className='flex items-center'>
            <p className='text-red-500'>{imageError}</p>
            <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8 text-red-500" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path className="animate-x" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </div>
        }
        </Form>
    </Wrapper>
        :
        <Wrapper>
        <Top>
            <Title>Post Service</Title>
        </Top>
        <Form onSubmit={(e) => handleSubmit(e)}>
            <Select
                onChange={(e) => {
                    setIsCategoryDisabled(true);
                    setSelectedCategory(e.target.value);
                }}
            >
                <Option disabled={isCategoryDisabled}>Category</Option>
                {
                    categories
                        // ?.filter(category => editCategory !== category._id)
                        ?.map(category => 
                            category.categoryName === 'Artisans' 
                                ? null
                                : <Option key={category._id} value={category._id}>{category.categoryName}</Option>
                        )
                }
            </Select>
            <Input className="hidden" onChange={(e) => setOccupation(occupationFromCategoryToCreate[0]?.categoryName)} placeholder="Occupation"/>
            <Input onChange={(e) => setAddress(e.target.value)} placeholder="Address"/>
            <div className="flex w-4/5 mt-4 gap-x-2 items-center justify-center">
                <SelectResidence
                    onChange={(e) => {
                        setIsStateDisabled(true);
                        setSelectedState(e.target.value)
                    }}
                >
                    <Option  disabled={isStateDisabled}>State</Option>
                    {
                        nigerianStates?.map((state, index) => 
                            <Option key={index} value={state}>{state}</Option>
                        )
                    }
                </SelectResidence>
                <SelectResidence
                    onChange={(e) => {
                        setIsLocalGogernmentDisabled(true);
                        setSelectedLocalGovernment(e.target.value);
                    }}
                >
                    <Option disabled={isLocalGogernmentDisabled}>Local Government</Option>
                    {
                        states[selectedState]?.map((state, index) => 
                            <Option key={index} value={state}>{state}</Option>
                        )
                    }
                </SelectResidence>
            </div>

            <Select
                onChange={(e) => {
                    setIsSexDisabled(true);
                    setSelectedSex(e.target.value);
                }}
                className="mt-4"
            >
                <Option disabled={isSexDisabled}>Sex</Option>
                    <Option value={"Male"}>{"Female"}</Option>
                    <Option value={"Male"}>{"Male"}</Option>
            </Select>
            <Text>
                <span>Description</span>
                <div>Please enter a short description about yourself and your services below</div>
            </Text>
            <TextArea onChange={(e) => setDescription(e.target.value)}></TextArea>
            <Text>
                <span>Add Photo</span>
                <div>The first image is the title image. You have an upload limit of 4 images.</div>
            </Text>
            <div className="flex w-4/5 items-center lg:w-1/2" >
                <PictureInputSection>
                    <PhotoUploadWrap onClick={handleIconClick}>
                        <FiPlus color="#9302E1"/>
                        <UploadFile type="file"accept="image/*" ref={fileInputRef} style={{ display: "none" }} multiple onChange={handleFileChange}/>
                    </PhotoUploadWrap>
                {selectedFilesCount > 0 ? (
                    <div className="">
                        <span>{selectedFilesCount} {selectedFilesCount > 1 ? "images" : "image"} selected</span>
                    </div>
                    ) : (
                    <div className="">
                        {/* <FiPlus color="#9302E1" size={32} /> */}
                        <span>No image selected</span>
                        </div>
                )}
                </PictureInputSection>
            </div>
            {
            (getUserInfoFromLocalStorage?.user?.isVerified && verifySuccess?.length === 0) ? 
            <Button type="submit">
                {isDataLoading ? (
                <CirclesWithBar
                    // className="justify-center "
                    height="35"
                    width="50"
                    color="#0b1f3f"
                    wrapperStyle={{"justifyContent": 'space-around'}}
                    visible={true}
                    outerCircleColor=""
                    innerCircleColor=""
                    barColor=""
                    ariaLabel='circles-with-bar-loading'
                />
                ) :
                    "Continue"
                }  
            </Button>
            :
            <p className="text-red-500 text-sm md:text-xl font-bold mt-12 md:mt-8">
                Please verify your account to continue
            </p>

            }
            {isDataSuccess &&
          <div className='flex items-center'>
            <p className='text-green-500'> Successfully uploaded Information</p>
            <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8 text-green-500" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path className="animate-draw" d="M5 13l4 4L19 7" />
            </svg>
          </div>
        }
        {isError &&
          <div className='flex items-center'>
            <p className='text-red-500'>Error creating upload. Please try again.</p>
            <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8 text-red-500" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path className="animate-x" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </div>
        }
        {imageError &&
          <div className='flex items-center'>
            <p className='text-red-500'>{imageError}.</p>
            <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8 text-red-500" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path className="animate-x" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </div>
        }
        </Form>
    </Wrapper>
    }
  </Container>;
};

export default UploadPage;

const Container = style.div`
width: 100vw;
// height: 150vh;
background: #C8A2C8;
display: flex;
justify-content: center;
align-items: center;
padding: 50px 0;
`
const Wrapper = style.div`
width: 80%;
// height: 90%;
// background: grey;
border-radius: 5px;
display: flex;
flex-direction: column;

@media screen and (max-width: 767px){
  width: 85%;
  padding-top: 50px;
}
`
const Top = style.div`
width: 100%;
height: 110px;
background: white;
display: flex;
justify-content: center;
align-items: center;
border-radius: 5px;
margin: 40px 0;
position: relative;
@media screen and (max-width: 767px){
    height: 80px;
    margin-bottom: 20px;
    margin-top: 1px;
}
`
const Title = style.div`
font-size: 40px;
font-weight: 400;
`
// const Nav = style.a`
// cursor: pointer;
// position: absolute;
// right: 60px;
// color: blue;
// @media screen and (max-width: 768px){
//     display: none;
// };
// `
const Form = style.form`
width: 100%;
// height: 80%;
background: white;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
border-radius: 5px;
padding: 50px 0;
`
const Select = style.select`
width: 51%;
height: 50px;
padding: 5px 10px;
border-radius: 5px;
border: solid lightgrey;
outline: none;
@media screen and (max-width: 768px){
    height: 45px;
    width: 80%;
};
@media screen and (max-width: 375px){
    height: 50px;
    width: 80%;
};
`

const SelectResidence = style.select`
width: 31.5%;
height: 50px;
padding: 5px 10px;
border-radius: 5px;
border: solid lightgrey;
outline: none;
@media screen and (max-width: 768px){
    height: 45px;
    width: 80%;
};
@media screen and (max-width: 375px){
    height: 50px;
    width: 80%;
};
`
const Option = style.option`
width: 100%;
height: 100px;
`
const Input = style.input`
width: 51%;
height: 38px;
padding: 5px 10px;
border-radius: 5px;
margin-top: 20px;
border: solid lightgrey;
outline: none;
background: #F5F5F5;
@media screen and (max-width: 768px){
    height: 50px;
    width: 80%;
};
@media screen and (max-width: 375px){
    height: 40px;
    width: 70%;
};
`
const Text = style.div`
width: 51%;
height: 8%;
// background: red;
margin: 18px 0;
span {
    font-weight: bold;
};
@media screen and (max-width: 768px){
    width: 80%;
};
@media screen and (max-width: 375px){
    width: 80%;
};
`
const TextArea = style.textarea`
width: 50%;
height: 170px;;
background: #F5F5F5;
border-radius: 5px;
border: solid lightgrey;
outline: none;
@media screen and (max-width: 768px){
    width: 80%;
};
@media screen and (max-width: 375px){
    width: 80%;
};
`
const PictureInputSection = style.div`
width: 50%;
height: 80px;
// background: #F5F5F5;
@media screen and (max-width: 768px){
    width: 40%;
};
@media screen and (max-width: 375px){
    width: 80%;
};
`
const PhotoUploadWrap = style.div`
width:70px;
height: 70px;
background: #F5F5F5;
border: solid lightgrey;
border-radius: 5px;
display: flex;
align-items: center;
justify-content: center;
`
// const Symbol = style.div``
// const Button = style.button`
// width: 50%;
// height: 60px;
// margin-top: 30px;
// background: #9302E1;
// border: none;
// border-radius: 5px;
// color: white;
// @media screen and (max-width: 768px){
//     width: 80%;
// margin-top: 40px;

// };
// @media screen and (max-width: 375px){
//     width: 80%;
// };
// `

const Button = style.button`
  width: 50%;
  height: 60px;
  margin-top: 30px;
  background-color: #b783b7;
  border: none;
  border-radius: 5px;
  color: white;
  transition: background-color 0.3s;

  &:hover {
    background-color: #9A5E9E;
  }

  @media screen and (max-width: 768px) {
    width: 80%;
    margin-top: 40px;
  }

  @media screen and (max-width: 375px) {
    width: 80%;
  }
`;
const UploadFile = style.input``
// const Container = style.div``
// const Container = style.div``
// const Container = style.div``
// const Container = style.div``
// const Container = style.div``
