export const ActionTypes = {
    FETCH_DATA_LOADING: 'FETCH_DATA_LOADING',
    FETCH_DATA_ERROR: 'FETCH_DATA_ERROR',
    SUCCESS: 'SUCCESS',
    SUCCESS_TIMEOUT: 'SUCCESS_TIMEOUT',
    ERROR_TIMEOUT: 'ERROR_TIMEOUT',
    VERIFY_MODAL: 'VERIFY_MODAL',
    VERIFY_TOKEN_LOADING: 'VERIFY_TOKEN_LOADING',
  }
  
  export const setLoadingData = () => ({
    type: ActionTypes.FETCH_DATA_LOADING,
  })
  export const setLoadingDataForToken = () => ({
    type: ActionTypes.VERIFY_TOKEN_LOADING,
  })

  export const setVerifyModal = () => ({
    type: ActionTypes.VERIFY_MODAL,
  })
  
  export const setDataError = (data) => ({
    type: ActionTypes.FETCH_DATA_ERROR,
    payload: data,
  })

  export const setSuccess = (data) => ({
    type: ActionTypes.SUCCESS,
    payload: data,
  })

  export const setSuccessTimeOut = () => ({
    type: ActionTypes.SUCCESS_TIMEOUT,
  })

  export const setErrorTimeOut = () => ({
    type: ActionTypes.ERROR_TIMEOUT,
  })
  

  const initialState = {
    loading: false,
    error: '',
    verifySuccess: [],
    verifyModal: false,
    loadTokenData: false,
  }

  const verifyReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case ActionTypes.FETCH_DATA_LOADING:
        return {
          ...state,
          loading: true,
        }
      case ActionTypes.VERIFY_TOKEN_LOADING:
        return {
          ...state,
          loadTokenData: true,
        }
      case ActionTypes.FETCH_DATA_ERROR:
        return {
          ...state,
          error: payload,
          loading: false,
          verifySuccess: [],
          loadTokenData: false,
        }
      case ActionTypes.SUCCESS:
        return {
          ...state,
          verifySuccess: payload,
          loading: false,
          error: '',
          loadTokenData: false,
        }
        case ActionTypes.SUCCESS_TIMEOUT:
            return {
              ...state,
              verifySuccess: [],
        }
        case ActionTypes.ERROR_TIMEOUT:
            return {
              ...state,
              error: '',
        }
        case ActionTypes.VERIFY_MODAL:
            return {
              ...state,
              verifyModal: !state.verifyModal,
        }
      default:
        return state
    }
  }
  export default verifyReducer