import React, { useEffect } from 'react';
import Hero from './Hero';
import styled from 'styled-components';
import Hero2 from './Hero2';

const LandingPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <Hero />
      <Hero2 />
    </Container>
  );
};

export default LandingPage;

const Container = styled.div`
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
`;
