import React, { useState } from "react";
import styled from "styled-components";
import { FaTimes } from "react-icons/fa"
import { AiOutlineMail, AiOutlineLock, AiFillEyeInvisible, AiFillEye } from "react-icons/ai"
import { BiSolidPhone } from "react-icons/bi"
import axios from 'axios';
import { CirclesWithBar } from 'react-loader-spinner';
import { useNavigate } from "react-router-dom"

const Register = ({setRegister}) =>{
    const navigate = useNavigate()

    const [userName, setUserName ] = useState('')
    const [userNameError, setUserNameError ] = useState('')
    const [email, setEmail ] = useState('')
    const [password, setPassword ] = useState('')
    const [passwordError, setPasswordError ] = useState('')
    const [phoneNumber, setPhoneNumber ] = useState('')
    const [phoneNumberError, setPhoneNumberError ] = useState('')
    const [isError, setIsError ] = useState('')
    const [isSuccess, setIsSuccess ] = useState(false)
    const [isDataLoading, setIsDataLoading ] = useState(false)
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);


    const handleRegister = async () => {
        setIsDataLoading(true);
        if(userName.length === 0) {
            setUserNameError("Please enter a user name")
            setIsDataLoading(false);
            return setTimeout(() => {
            setUserNameError("")
            }, 4000);
        }
        if(password.length === 0) {
            setPasswordError("Password field cannot be empty")
            setIsDataLoading(false);
            return setTimeout(() => {
                setPasswordError("")
            }, 4000);
        }

        if(phoneNumber.length !== 11 || !phoneNumber.startsWith("0")) {
            setPhoneNumberError("Invalid phone number format. Must start with zero and be 11 digits.")
            setIsDataLoading(false);
            return setTimeout(() => {
                setPhoneNumberError("")
            }, 4000);
        }

        let slicedNumber = phoneNumber.slice(1)
        let vendorNumber = "+234" + slicedNumber

        let newUserInfo = {
            userName,
            email,
            password,
            phoneNumber: vendorNumber,
        }

        const config = {
            headers: {
                'ngrok-skip-browser-warning': 'true',
                'Content-Type': 'application/json',
            },
          };

          await axios.post('https://hanaka-backend.onrender.com/api/register', newUserInfo, config)
            .then(response => {
              setIsSuccess(true);
              setTimeout(() => {
                setIsSuccess(false);
                setRegister(false)
              }, 7000);
              setIsDataLoading(false);
            })
            .catch(error => {
                setIsError(error?.response?.data?.message);
                setTimeout(() => setIsError(''), 5000);
                setIsDataLoading(false);
            });
    }

    const handleIsPasswordVisible = () => {
        setIsPasswordVisible(!isPasswordVisible)
    }

    return(
        <Container>
            <Wrapper>
                <CancelHold onClick={() =>{
                    setRegister(false);
                }}>
                    <FaTimes/>
                </CancelHold>
                <Registration>Registration</Registration>
                <Data>
                    <InputHold>
                        <AiOutlineMail/>
                        <Input onChange={(e) => setUserName(e.target.value)} placeholder="Username *"/>
                    </InputHold>
                        {
                            userNameError &&
                            <p className="text-red-500 text-xs">{userNameError}</p>
                        }
                    <InputHold>
                        <AiOutlineMail/>
                        <Input type="email" onChange={(e) => setEmail(e.target.value)} placeholder="Email"/>
                    </InputHold>
                    <InputHold>
                        <AiOutlineLock className="mr-3"/>
                        <Input type={`${isPasswordVisible ? "text" : "password"}`} onChange={(e) => setPassword(e.target.value)} placeholder="Password *"/>
                        {isPasswordVisible ? <AiFillEye id='passwordShoworHide' className='w-9 cursor-pointer' onClick={() => handleIsPasswordVisible()}/>
                        :
                        <AiFillEyeInvisible id='passwordShoworHide' className='w-9 cursor-pointer' onClick={() => handleIsPasswordVisible()}/>
                        }
                    </InputHold>
                    {
                            passwordError &&
                            <p className="text-red-500 text-xs">{passwordError}</p>
                        }
                    <InputHold>
                        <BiSolidPhone/>
                        <Input type="number" placeholder="Phone Number *" onChange={(e) => setPhoneNumber(e.target.value)}/>
                    </InputHold>
                    {
                            phoneNumberError &&
                            <p className="text-red-500 text-xs">{phoneNumberError}</p>
                        }
                    <ResetPassword onClick={()=>{
                        navigate("/account-recovery");
                        setRegister(false);
                    }}>Forgot Password?</ResetPassword>
                    <p className="text-xs text-red-950">All fields marked with * are compulsory</p>
                    <Button
                        onClick={() => handleRegister()}
                        disabled={isDataLoading}
                    >
                    {isDataLoading ? (
                        <CirclesWithBar
                            height="35"
                            width="100"
                            color="#fff"
                            wrapperStyle={{}}
                            visible={true}
                            outerCircleColor=""
                            innerCircleColor=""
                            barColor=""
                            ariaLabel='circles-with-bar-loading'
                        />
                        ) :
                        "Register"
                    }
                    </Button>
                    {isError &&
                        <div className='flex items-center'>
                            <p className='text-red-500  text-xs md:text-base'>{isError}</p>
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8 text-red-500" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <path className="animate-x" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </div>
                    }
                    {isSuccess &&
                        <div className='flex items-center justify-center'>
                            <p className='text-green-500 text-sm md:text-base'>Successfully registered user, kindly log in.</p>
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 text-green-500 text-xs" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                <path className="animate-draw text-xs" d="M5 13l4 4L19 7" />
                            </svg>
                        </div>
                    }
                </Data>
            </Wrapper>
        </Container>
    )
};

export default Register;

const Container = styled.div`
width: 30%;
height: 75%;
background-color: white;
display: flex;
justify-content: center;
align-items: center;
position: relative;
border-radius: 10px;
box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
margin-top: 20px;

@media Screen and (max-width: 768px){
    width: 70%;
    padding: 10px 0;
    height: 70%;
}
@media Screen and (max-width: 525px){
    width: 90%;
    height: 97%;
    padding-top: 135px;
}
`;

const Wrapper = styled.div`
width: 95%;
height: 95%;
position: absolute;
display: flex;
flex-direction: column;
align-items: center;
`;

const CancelHold = styled.div`
width: 100%;
display: flex;
justify-content: flex-end;
color: #6c3c6c;
font-size: 30px;
font-weight: 500;
cursor: pointer;
`;

const Registration = styled.div`
width: 95%;
display: flex;
justify-content: center;
font-size: 25px;
color: #424242;
font-weight: 400;

`;

const Data = styled.div`
width: 90%;
height: 80%;
display: flex;
flex-direction: column;
margin-top: 20px;
`;
const InputHold = styled.div`
width: 100%;
height: 40px;
display: flex;
justify-content: space-between;
padding: 5px;
margin: 10px 0;
align-items: center;
border: 1px solid grey;
border-radius: 5px;
`;
const Input = styled.input`
width: 90%;
border: none;
outline: none;


::placeholder{
    color: #424242;

}


`;
const ResetPassword = styled.div`
width: 100%;
display :flex;
justify-content: flex-end;
align-items: center;
color: #6c3c6c;
cursor: pointer;
font-weight: 500;
font-size: 15px;
text-decoration: none;
`;


const Button = styled.button`
border: none;
width: 100%;
height: 30px;
padding: 25px 10px;
border-radius: 5px;
background-color: #6c3c6c;
color: white;
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
margin: 10px 0;
transition: background-color 0.3s;

&:hover {
    background-color: #633b63;
  }
`;


