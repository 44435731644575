import {
    combineReducers,
    legacy_createStore as createStore,
    applyMiddleware,
  } from 'redux'
  import thunk from 'redux-thunk'
  import logInReducer from './Login'
  import verifyReducer from './Verify'
  import searchReducer from './Search'
  
  const rootReducer = combineReducers({
    logInReducer,
    verifyReducer,
    searchReducer,
  })
  
  const store = createStore(rootReducer, applyMiddleware(thunk))
  
  export default store