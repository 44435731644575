import React, { useState } from "react";
// import { AiFillMail } from "react-icons/ai";
import styled from "styled-components";
import axios from 'axios';
import { CirclesWithBar } from 'react-loader-spinner';
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { useNavigate } from "react-router-dom";


const NewPassword = () => {
    const navigate = useNavigate()

    const [ isDataLoading, setIsDataLoading] = useState(false);
    const [ isSuccess, setIsSuccess] = useState('');
    const [ isError, setIsError] = useState('');
    const [ passwordValue, setPasswordValue] = useState('');
    const [ passwordValueError, setPasswordValueError] = useState('');
    const [ tokenValue, setTokenValue] = useState('');
    const [ isPasswordVisible, setIsPasswordVisible ] = useState(false);

    const getUserEmailOrPasswordFromLocalStorage = JSON.parse(localStorage.getItem('userEmailOrNumber')) || {};

    const handleRegister = async () => {
        setIsDataLoading(true);
        if(passwordValue.length === 0) {
            setPasswordValueError("Password field is required");
            setIsDataLoading(false);
            return setTimeout(() => {
                setPasswordValueError('');
              }, 7000);
        }
        let vendorNumber = '';

        if(getUserEmailOrPasswordFromLocalStorage?.phoneNumber?.length > 0) {
            let slicedNumber = getUserEmailOrPasswordFromLocalStorage?.phoneNumber
            vendorNumber = slicedNumber
        }

        let userInfo = {
            email: getUserEmailOrPasswordFromLocalStorage?.email?.length > 0 ? getUserEmailOrPasswordFromLocalStorage?.email : "",
            phoneNumber: vendorNumber === undefined ? "" : vendorNumber,
            token: tokenValue,
            newPassword: passwordValue,
        }

        const config = {
            headers: {
                'ngrok-skip-browser-warning': 'true',
                'Content-Type': 'application/json',
            },
          };

          await axios.post('https://hanaka-backend.onrender.com/api/resetPassword', userInfo, config)
            .then(response => {
            setIsSuccess(response?.data?.message)
            setTimeout(() => {
                setIsSuccess('');
                navigate('/');
            }, 7000);
            setIsDataLoading(false);
            localStorage.removeItem('userEmailOrNumber');
            })
            .catch(error => {
              console.log(error?.response?.data)
              setIsError(error?.response?.data?.message);
              setTimeout(() => setIsError(''), 5000);
              setIsDataLoading(false);
            });
    }

    const handleIsPasswordVisible = () => {
        setIsPasswordVisible(!isPasswordVisible)
    }

    return(
        <Container>
            <Wrapper>
                <Title>Password Reset</Title>
                <Span>Fill in the token we sent to you and New password</Span>
                <InputHold>
                    <Input placeholder="Enter the token"  onChange={(e) => setTokenValue(e.target.value)}/>
                </InputHold>
                <InputHold>
                    <Input type={`${isPasswordVisible ? "text" : "password"}`} placeholder="Enter your New password" onChange={(e) => setPasswordValue(e.target.value)}/>
                    {isPasswordVisible ? <AiFillEye id='passwordShoworHide' className='w-9 cursor-pointer' onClick={() => handleIsPasswordVisible()}/>
                        :
                        <AiFillEyeInvisible id='passwordShoworHide' className='w-9 cursor-pointer' onClick={() => handleIsPasswordVisible()}/>
                    }
                </InputHold>
                    {
                        passwordValueError.length > 0 &&
                        <p className=" text-xs md:text-sm text-red-500">{passwordValueError}</p>
                    }
                <Button onClick={() => handleRegister()}>
                    {isDataLoading ? (
                        <CirclesWithBar
                            height="35"
                            width="100"
                            color="#fff"
                            wrapperStyle={{}}
                            visible={true}
                            outerCircleColor=""
                            innerCircleColor=""
                            barColor=""
                            ariaLabel='circles-with-bar-loading'
                            />
                        ) :
                        "Submit"
                    }
                </Button>
                {isSuccess.length > 0 &&
                    <div className='flex items-center justify-center'>
                        <p className='text-green-500 text-xs md:text-base'>{isSuccess + " You can now sign into your account 😊"}</p>
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 text-green-500 text-xs" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <path className="animate-draw text-xs" d="M5 13l4 4L19 7" />
                        </svg>
                    </div>
                }
                {isError.length > 0 &&
                    <div className='flex items-center'>
                        <p className='text-red-500  text-xs md:text-base'>{isError}</p>
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8 text-red-500" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <path className="animate-x" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </div>
                }
            </Wrapper>
        </Container>
    )
};

export default NewPassword;

const Container = styled.div`
width: 100%;
height: 90vh;
background-color: whitesmoke;
display: flex;
justify-content: center;
align-items: center;
`;
const Wrapper = styled.div`
width: 40%;
height: 70%;
background-color: white;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
padding: 0px 25px;

@media Screen and (max-width: 768px){
    width: 95%;
    height: 85%;
}
@media Screen and (min-width: 1024px){
    width: 40%;
    height: 65%;
}
`;
const Title = styled.div`
font-size: 24px;
font-weight: 700;
margin-bottom: 15px;
color: rgb(75, 75, 75);
`;
const InputHold = styled.div`
width: 100%;
height: 40px;
display: flex;
justify-content: space-between;
padding: 30px 5px;
margin: 10px 0;
align-items: center;
border: 1px solid rgb(180, 180, 180);
border-radius: 5px;
`;
const Input = styled.input`
width: 95%;
border: none;
outline: none;

::placeholder{
    color: #424242;

}


`;

const Span = styled.div`
/* font-family: monospace; */
text-align: center;
/* cursor: pointer; */
width: 100%;
display: flex;
justify-content: center;
align-items: center;
font-size: 13px;
color: rgb(75, 75, 75);

@media Screen and (min-width: 768px){
  font-size: 18px;
}
`;


const Button = styled.button`
border: none;
width: 100%;
height: 30px;
padding: 25px 10px;
border-radius: 5px;
background-color: #b783b7;
color: white;
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
margin: 10px 0;

transition: background-color 0.3s;

&:hover {
  background-color: #9A5E9E;
}

`;
