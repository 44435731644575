import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { CirclesWithBar } from 'react-loader-spinner';
import { useNavigate } from "react-router-dom";
import '../SettingsPage/Settings.css';


const CreateAdvert = () => {
  const [advertText, setAdvertText] = useState('');
  const [advertHeadline, setAdvertHeadline] = useState('');
  const [imagePreview, setImagePreview] = useState(null);
  const [advertImage, setAdvertImage] = useState(null);
  const [getAdverts, setGetAdverts] = useState([]);
  const [advertSuccess, setAdvertsSuccess] = useState('');
  const [advertError, setAdvertsError] = useState('');
  const [advertLoading, setAdvertsLoading] = useState(false);

  const getUserInfoFromLocalStorage = JSON.parse(localStorage.getItem('loginInfo')) || {};
  const navigate = useNavigate()

  const handleAdvertTextChange = (e) => {
    setAdvertText(e.target.value);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setAdvertImage(file);
    if (file) {
      // Create a preview of the selected image
      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setImagePreview(null);
    }
  };

  const handleCreateAdvert = async () => {
    setAdvertsLoading(true);

    const config = {
        headers: {
          'ngrok-skip-browser-warning': 'true', //Remove this when you use the hosted server
          'Content-Type': 'multipart/form-data',

          'Authorization': `Bearer ${getUserInfoFromLocalStorage?.token}`,
        },
    };

    const uploadAdverts = {
        advertImage,
        advertInformation : advertText,
        advertHeadline,
    }
    try{
        let advertMessage =  await axios.post('https://hanaka-backend.onrender.com/api/advert', uploadAdverts, config);
        setAdvertsSuccess(advertMessage?.data?.message);
        setTimeout(() => {
            setAdvertsSuccess('');
            navigate('/');
        }, 5000);
        setAdvertsLoading(false);
    } catch(err){
        setAdvertsError(err.response?.data?.message);
        setTimeout(() => {
            setAdvertsError('');
        }, 5000);
        setAdvertsLoading(false);
    }
  };

  const handleGetAdverts = async() => {
    const config = {
        headers: {
          'ngrok-skip-browser-warning': 'true', //Remove this when you use the hosted server
          'Content-Type': 'application/json',
        },
      };

    let getAllUploads = await axios.get('https://hanaka-backend.onrender.com/api/advert', config);
    setGetAdverts(getAllUploads?.data?.data);
  };

    useEffect(() => {
        if(getUserInfoFromLocalStorage?.token === undefined || !(getUserInfoFromLocalStorage?.user?.isAdmin)) {
          return navigate('/');
        }
        handleGetAdverts()
        window.scrollTo(0, 0);

     // eslint-disable-next-line
    },[]);

  return (
    <div className="max-w-md mx-auto p-6 md:pt-24">
      <h2 className="text-2xl font-bold mb-4">Create Advert</h2>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-2" htmlFor="advertText">
          Advert Headline
        </label>
        <input
          id="advertHeadline"
          name="advertImage"
          value={advertHeadline}
          onChange={(e) => setAdvertHeadline(e.target.value)}
          className="border border-gray-300 rounded-md w-full py-2 px-3 outline-none"
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-2" htmlFor="advertText">
          Advert Text
        </label>
        <textarea
          id="advertText"
          name="advertText"
          value={advertText}
          onChange={handleAdvertTextChange}
          className="border border-gray-300 rounded-md w-full py-2 px-3 outline-none"
          rows="4"
        ></textarea>
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-2" htmlFor="image">
          Image
        </label>
        <input
          type="file"
          accept="image/*"
          id="image"
          name="image"
          onChange={handleImageChange}
          className="border border-gray-300 rounded-md w-full py-2 px-3"
        />
      </div>
      {imagePreview && (
        <div className="mb-4">
          <img src={imagePreview} alt="Avatar Preview" className="max-w-full h-auto rounded" />
        </div>
      )}
      {
        getAdverts?.length <= 1 ?

        <button
        onClick={handleCreateAdvert}
        className="categoryButton text-white font-medium py-2 px-4 rounded-md "
      >
        {advertLoading ? (
            <CirclesWithBar
                height="25"
                width="94"
                color="#0b1f3f"
                wrapperStyle={{"justifyContent": 'space-around'}}
                visible={true}
                outerCircleColor=""
                innerCircleColor=""
                barColor=""
                ariaLabel='circles-with-bar-loading'
                />
            ) :
            "Create Advert"
        }
      </button>
      :
      <p className=' text-sm text-red-400'>Kindly delete one or more Images from the home page</p>
      }
        {advertSuccess.length > 0 &&
            <div className='flex items-center justify-center'>
                <p className='text-green-500 text-xs md:text-base'>{advertSuccess}</p>
                <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 text-green-500 text-xs" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path className="animate-draw text-xs" d="M5 13l4 4L19 7" />
                </svg>
            </div>
        }
        {advertError.length > 0 &&
            <div className='flex items-center'>
                <p className='text-red-500  text-xs md:text-base'>{advertError}</p>
                <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8 text-red-500" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path className="animate-x" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </div>
        }
    </div>
  );
};

export default CreateAdvert;
