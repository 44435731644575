import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CirclesWithBar } from 'react-loader-spinner';
import { setVerifyModal } from '../Redux/Verify';
import '../SettingsPage/Settings.css';
import { postVerifyUser, postVerifyToken } from '../Redux/VerifyApi';

const VerificationModal = () => {
  const dispatch = useDispatch()

  const [token, setToken] = useState('');
  // const [verifyToken, setVerifyToken] = useState('');
  const [tokenError, setTokenError] = useState(false);

  const {
    loading,
    error,
    verifySuccess,
    verifyModal,
    loadTokenData,
  } = useSelector((state) => state.verifyReducer)

  const getUserInfoFromLocalStorage = JSON.parse(localStorage.getItem('loginInfo')) || {};

  let userEmailOrPassword = {
    email: getUserInfoFromLocalStorage?.user?.email,
    phoneNumber: getUserInfoFromLocalStorage?.user?.phoneNumber
  }

  const handleVerifyUserByToken = () => {
    if(token.length === 0) {
      setTokenError(true);
      return setTimeout(() => {
        setTokenError(false);
      }, 4000);
    }
    let userToken = {
      token
    }
    dispatch(postVerifyToken(userToken, getUserInfoFromLocalStorage?.token))
  }

  // console.log(verifyToken)

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center z-30 mx-3 ${
        verifyModal ? 'opacity-100' : 'opacity-0 pointer-events-none'
      } transition-opacity`}
    >
      <div
        className={`fixed inset-0 bg-gray-800 opacity-50 backdrop-blur-md transition-opacity ${
          verifyModal ? 'opacity-100' : 'opacity-0 pointer-events-none'
        }`}
      ></div>
      <div className="bg-white w-full max-w-md p-4 rounded-lg shadow-md z-20">
        <div className="text-center">
          <h2 className="text-2xl font-semibold mb-4">Verify Your Account</h2>
          <p className="text-gray-600">
            We sent a verification code to your email and phone number. Please enter the code to verify your account.
          </p>
        </div>
        <div className="mt-4">
          <input
            type="text"
            placeholder="Verification Code"
            className="w-full px-4 py-2 border rounded outline-none"
            onChange={(e) => setToken(e.target.value)}
          />
        </div>
        {
          tokenError && <p className='text-red-500 text-sm md:text-xs	'> This field cannot be empty</p>
        }
        <p className='text-sm text-amber-950 mt-3'>
          Didn't get a reset token?{' '}
          <button
            className="text-blue-500 hover:underline cursor-pointer focus:outline-none"
            onClick={() => dispatch(postVerifyUser(userEmailOrPassword))} // Replace handleClick with the APi call to get token again
          >
            Click here
          </button>{' '}
          to get another token sent to you!
        </p>
        {loading ? (
          <p className="text-blue-500 flex items-center text-xs md:text-base">
            Sending, please wait
            <svg
              className="animate-spin h-5 w-5 ml-2"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8v-2"
              ></path>
            </svg>
          </p>
        ) : (
          ""
        )}

        <div className="mt-4 flex justify-center">
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            onClick={() => handleVerifyUserByToken()}
          >
            {loadTokenData ? (
              <CirclesWithBar
                height="25"
                width="40"
                color="#0b1f3f"
                wrapperStyle={{}}
                visible={true}
                outerCircleColor=""
                innerCircleColor=""
                barColor=""
                ariaLabel='circles-with-bar-loading'
              />
              ) :
              "verify"
            }
          </button>
          <button
            onClick={() => dispatch(setVerifyModal())}
            className="ml-2 text-blue-500 hover:underline"
          >
            Cancel
          </button>
        </div>
          {verifySuccess &&
            <div className='flex items-center justify-center mt-2'>
                    <p className='text-green-500 text-xs md:text-sm'>{`Successfully Verified User`}</p>
                   <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 text-green-500" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                   <path className="animate-draw" d="M5 13l4 4L19 7" />
                </svg>
            </div>
          }
          {error &&
            <div className='flex items-center'>
                <p className='text-red-500'>{error}</p>
                <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8 text-red-500" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path className="animate-x" d="M6 18L18 6M6 6l12 12" />
                </svg>
             </div>
          }
      </div>
    </div>
  );
};

export default VerificationModal;
