import React, { useEffect, useState } from "react";
import style from 'styled-components'
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import Slider from "./Slider"
import ImageModal from "./ImageModal";

const DetailPage = () => {
    const location = useLocation();
    const { key } = location.state;
    
    const [getVendorDetail, setGetVendorDetail] = useState({})
    const [getUserDetail, setGetUserDetail] = useState({})
    const [viewPhoneNumber, setViewPhoneNumber] = useState(false)
    const [imageModal, setImageModal] = useState(false)

    const getUploadDetail = async() => {
        const config = {
            headers: {
              'ngrok-skip-browser-warning': 'true', //Remove this when you use the hosted server
              'Content-Type': 'application/json',
            },
        };

        let geVendorInformation = await axios.get(`https://hanaka-backend.onrender.com/api/getUploadDetail/${key}`, config);
        setGetVendorDetail(geVendorInformation?.data?.data)

        let getUserInformation = await axios.get(`https://hanaka-backend.onrender.com/api/getOneUserByVisiting/${geVendorInformation?.data?.data?.user}`, config)
        setGetUserDetail(getUserInformation?.data?.data)
    }

    useEffect(() => {
        getUploadDetail()
        window.scrollTo(0, 0);
     // eslint-disable-next-line
    },[]);

  return <Container>
    <Wrapper>
        <GalleryHolder>
            <SliderWrap>
                <Slider prop={getVendorDetail?.images}/>
            </SliderWrap>
            {/* <PhotoHolder>
                {
                   getVendorDetail?.images?.map((image, index) => 
                       <Photos key={index} src={image} alt="Vendors work image"/>
                   ) 
                }
            </PhotoHolder> */}
        </GalleryHolder>
        <ProfileHolder>
            <PersonalInfo>
                <Wrap>
                    <NameSection>
                        <NameSectionWrap>
                            <img className="hover:cursor-pointer" src={getUserDetail?.profileImage} alt="Vendor's avatar" onClick={() => setImageModal(!imageModal)}/>
                            <div>{getUserDetail?.userName ? getUserDetail?.userName : ""}</div>
                        </NameSectionWrap>
                        <span>{getVendorDetail?.occupation ? getVendorDetail?.occupation : ""}</span>
                    </NameSection>
                    <BioWrap>
                        <span>Bio</span>
                        <div>{getVendorDetail?.description ? getVendorDetail?.description : ""}</div>
                    </BioWrap>
                    <LocationWrap>
                        <div className="wrap">
                            <span>Location</span>
                            <div>{`${getVendorDetail?.state ? getVendorDetail?.state : ""} ${getVendorDetail?.location ? getVendorDetail?.location : ""}`}</div>
                        </div>
                        <div className="wrap">
                            <span>Address</span>
                            <div>{getVendorDetail?.address ? getVendorDetail?.address : ""}</div>
                        </div>
                    </LocationWrap>
                    <button onClick={() => setViewPhoneNumber(!viewPhoneNumber)}>{viewPhoneNumber ? getUserDetail?.phoneNumber : "Show contact detail"}</button>
                    {viewPhoneNumber ? (
                    <div>
                        Phone-Number: {getUserDetail?.phoneNumber ? getUserDetail.phoneNumber : ''}{" "}<br/>
                        Email: {getUserDetail?.email ? getUserDetail.email : ''}
                    </div>
                    ) : null}

                </Wrap>
                {
                    imageModal &&
                    <ImageModal imageUrl={getUserDetail?.profileImage} closeModal={() => setImageModal(!imageModal)}/>
                }
            </PersonalInfo>
        </ProfileHolder>
    </Wrapper>
  </Container>;
};

export default DetailPage;

const Container = style.div`
width: 100vw;
// height: 79vh;
background: #C8A2C8;
display: flex;
align-items: center;
justify-content: center;
padding-top: 90px;

@media screen and (max-width: 824px){
    padding-top: 65px;
    // height: 209vh;
};
` 
const Wrapper = style.div`
width: 70%;
// height: 100%;
display: flex;
align-items: center;
justify-content: space-between;
@media screen and (max-width: 1200px){
    width: 80%;
};
@media screen and (max-width: 922px){
    width: 90%;
};
@media screen and (max-width: 824px){
    flex-direction: column;
};
` 
const GalleryHolder = style.div`
width: 45%;
height: 100%;
@media screen and (max-width: 824px){
    width: 80%;
};
@media screen and (max-width: 548px){
    width: 90%;
};
` 
const ProfileHolder = style.div`
width: 47%;
height: 100%;
background: #fdfdfd;
align-self: flex-start;
@media screen and (max-width: 824px){
    align-self: center;
};
@media screen and (max-width: 824px){
    margin: 30px 0;
    width: 80%;
};
@media screen and (max-width: 548px){
    width: 90%;
};
` 
const PersonalInfo = style.div`
width: 100%;
min-height: 420px;
// background: blue;
border-radius: 10px;
box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
display: flex;
align-items: center;
justify-content: center;
padding: 20px 0;
` 
const SliderWrap = style.div`
width: 100%;

// background: blue;
border-radius: 10px;
margin-bottom: 35px;
box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
@media screen and (max-width: 824px){
    height:  500px;
};
` 
// const PhotoHolder = style.div`
// width: 100%;
// height:  160px;
// // background: white;
// display: flex;
// align-items: center;
// justify-content: space-between;
// ` 
// const Photos = style.img`
// width: 30%;
// height:  100%;
// border-radius: 10px;
// // background: blue;
// box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
// object-fit: cover;
// object-position: top;
// ` 
const NameSection = style.div`
width: 100%;
height: 26%;
// background: red;
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-around;
span{
    width: 90%;
    display: flex;
    align-items: flex-start;
    font-size: 26px;
    font-weight: 400;
    color: grey;
}
` 
const BioWrap = style.div`
width: 90%;
height: 30%;
// background: pink;
display: flex;
flex-direction: column;
span {
    margin-top: 12px;
    margin-bottom: 6px;
    font-size: 26px;
    font-weight: 400;
};
div {
    // color: grey;
    margin-bottom: 10px;
}
` 
const LocationWrap = style.div`
width: 90%;
height: 20%;
// background: green;
display: flex;
align-items: center;
justify-content: space-between;
margin-bottom: 25px;
div.wrap {
    width: 40%;
    height: 100%;
    // background: green;
    span {
        margin-top: 12px;
        margin-bottom: 10px;
        font-size: 26px;
        font-weight: 400;
    };
}
` 
const Wrap = style.div`
width: 90%;
height: 90%;
// background: blue;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
button {
    width: 80%;
    height: 50px;
    border-radius: 5px;
    border: none;
    color: white;
    background: #6c3c6c;
}
` 
const NameSectionWrap = style.div`
width: 100%;
height: 50%;
display: flex;
align-items: center;
// background: white;
justify-content: center;
img {
    width: 70px;
    height: 70px;
    border-radius: 100%;
    object-fit: cover;
    margin-right: 10px;
};
div {
    font-size: 26px;
    font-weight: 700;
    @media screen and (max-width: 824px){
        width: 100%;
    };
}
` 
// const Container = style.div`` 
// const Container = style.div`` 
// const Container = style.div`` 
// const Container = style.div`` 
// const Container = style.div`` 
// const Container = style.div`` 
