// import React, { useState, useEffect } from 'react';

// const HelpCard = ({ selectedTag }) => {
//   const [isHelpVisible, setIsHelpVisible] = useState(false);

//   // const toggleHelp = () => {
//   //   setIsHelpVisible(!isHelpVisible);
//   // };

//   useEffect(() => {
//     const handleKeyDown = (e) => {
//       if (e.key === 'Escape') {
//         selectedTag();
//       }
//     };
//     window.addEventListener('keydown', handleKeyDown);

//     setTimeout(() => {
//       setIsHelpVisible(true);
//     }, 10);

//     return () => {
//       window.removeEventListener('keydown', handleKeyDown);
//     };
//     // eslint-disable-next-line
//   }, []);

//   const closeOnOverlayClick = (e) => {
//     if (e.target.classList.contains('justify-center')) {
//       selectedTag();
//     }
//   };

//   return (
//     <div className={`fixed inset-0 flex items-center justify-center z-20`} onClick={ closeOnOverlayClick }>
//       <div className={`fixed inset-0 bg-gray-800 opacity-50 transition-opacity ${isHelpVisible ? 'opacity-100' : 'opacity-0'}`}></div>
//       <div className={`fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center`}>
//         <div className={`flex flex-col items-center justify-center max-w-md w-full p-4 bg-white rounded-lg shadow-lg transition-opacity ${isHelpVisible ? 'opacity-100' : 'opacity-0'} mx-4 md:mx-0`}  style={{height: "50vh"}}>
//           <div className=" block">
//           <h2 className="text-2xl font-semibold text-gray-800 mb-2">Need Help?</h2>
//           <p className="text-gray-700">
//             If you need assistance or have any questions, please feel free to contact us on.
//           </p>
//           <p>+2348134312367 OR ejuwagboe@gmail.com</p>
//           {/* <p>+2347032887144 OR ekens2u@gmail.com</p> */}
//           </div>
//           <div className='flex w-full justify-center gap-x-3 mt-3'>
//               <button className=" text-white py-2 px-4 rounded categoryButton transition-duration-300 w-1/4" onClick={() => selectedTag()}>
//                 Cancel
//               </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default HelpCard;

import React, { useState, useEffect } from 'react';

const HelpCard = ({ selectedTag }) => {
  const [isHelpVisible, setIsHelpVisible] = useState(false);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        selectedTag();
      }
    };
    window.addEventListener('keydown', handleKeyDown);

    setTimeout(() => {
      setIsHelpVisible(true);
    }, 10);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
    // eslint-disable-next-line
  }, []);

  const closeOnOverlayClick = (e) => {
    if (e.target.classList.contains('justify-center')) {
      selectedTag();
    }
  };

  return (
    <div className={`fixed inset-0 flex items-center justify-center z-20`} onClick={ closeOnOverlayClick }>
      <div className={`fixed inset-0 bg-gray-800 opacity-50 transition-opacity ${isHelpVisible ? 'opacity-100' : 'opacity-0'}`}></div>
      <div className={`fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center`}>
        <div className={`flex flex-col items-center justify-center max-w-md w-full p-4 bg-white rounded-lg shadow-lg transition-opacity ${isHelpVisible ? 'opacity-100' : 'opacity-0'} mx-4 md:mx-0`}  style={{height: "50vh"}}>
          <div className=" block">
            <h2 className="text-2xl font-semibold text-gray-800 mb-2">Need Help?</h2>
            <p className="text-gray-700">
              If you need assistance or have any questions, please feel free to contact us on.
            </p>
            <a href="tel:+2348134312367">+2348134312367</a> {/* Use tel: protocol for phone number */}
            <br />
            <a href="mailto:ejuwagboe@gmail.com">ejuwagboe@gmail.com</a> {/* Use mailto: protocol for email */}
          </div>
          <div className='flex w-full justify-center gap-x-3 mt-3'>
            <button className="text-white py-2 px-4 rounded categoryButton transition-duration-300 w-1/4" onClick={() => selectedTag()}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpCard;
