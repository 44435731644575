import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FaTimes } from "react-icons/fa"
import { AiOutlineMail, AiOutlineLock, AiFillEyeInvisible, AiFillEye } from "react-icons/ai"
import { BiSolidPhone } from "react-icons/bi"
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { CirclesWithBar } from 'react-loader-spinner';
import "../SettingsPage/Settings.css"
import { postLoginUser } from "../Redux/LoginApi";
// import Register from "./Register";


const SignIn = ({setSignin}) =>{

    const [email, setEmail ] = useState('')
    const [phoneNumber, setPhoneNumber ] = useState('')
    const [password, setPassword ] = useState('')
    const [wrongPassword, setWrongPassword ] = useState(false)
    const [wrongPhoneNumber, setWrongPhoneNumber ] = useState(false)
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const {
        loading,
        error,
        loginSuccess,
      } = useSelector((state) => state.logInReducer)

      const handleSubmit = (e) => {
          e.preventDefault()
        if (email.length > 0 || phoneNumber.length === 11) {
            // At least one of the fields is correct
          } else {
            setWrongPhoneNumber(true);
            setTimeout(() => setWrongPhoneNumber(false), 15000);
            return;
          }
        if (setWrongPassword.length === 0 ) {
            setWrongPhoneNumber(true);
            return setTimeout(() => setWrongPhoneNumber(false), 15000);
        }

        let slicedNumber = phoneNumber.slice(1)
        let vendorNumber = "+234" + slicedNumber
          const userDetail = { email, phoneNumber: vendorNumber, password }

        dispatch(postLoginUser(userDetail))
        setWrongPhoneNumber(false)
        setWrongPhoneNumber(false)
        }

        useEffect(() => {
            if (loginSuccess) {
              const timeoutId = setTimeout(() => {
                navigate('/');
                setSignin(false);
              }, 4000);

              // Clear the timeout if the component unmounts or if loginSuccess changes to false before the timeout completes
              return () => {
                clearTimeout(timeoutId);
              };
            }
            // eslint-disable-next-line
          }, [loginSuccess]);

          const handleIsPasswordVisible = () => {
            setIsPasswordVisible(!isPasswordVisible)
        }

    return(
        <Container>
            <Wrapper>
                <CancelHold onClick={() =>{
                        setSignin(false);
                    }}>
                    <FaTimes />
                </CancelHold>
                <Registration>Sign In</Registration>
                <span className="text-xs	">You can use either Email or Phone number</span>
                <Data>
                    <InputHold>
                        <AiOutlineMail/>
                        <Input type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)}/>
                    </InputHold>
                    <InputHold>
                        <AiOutlineLock className="mr-3"/>
                        <Input type={`${isPasswordVisible ? "text" : "password"}`} placeholder="Password" onChange={(e) => setPassword(e.target.value)}/>
                        {isPasswordVisible ? <AiFillEye id='passwordShoworHide' className='w-9 cursor-pointer' onClick={() => handleIsPasswordVisible()}/>
                        :
                        <AiFillEyeInvisible id='passwordShoworHide' className='w-9 cursor-pointer' onClick={() => handleIsPasswordVisible()}/>
                        }
                    </InputHold>
                    <InputHold>
                        <BiSolidPhone/>
                        <Input type="number" placeholder="070312456789" required onChange={(e) => setPhoneNumber(e.target.value) }/>
                    </InputHold>
                    <ResetPassword onClick={()=>{
                        navigate("/account-recovery");
                        setSignin(false);
                    }}>Forgot Password?</ResetPassword>
                    <Button onClick={(e) => handleSubmit(e)}>
                    {loading ? (
                        <CirclesWithBar
                            height="35"
                            width="100"
                            color="#fff"
                            wrapperStyle={{}}
                            visible={true}
                            outerCircleColor=""
                            innerCircleColor=""
                            barColor=""
                            ariaLabel='circles-with-bar-loading'
                        />
                        ) :
                        "Sign in"
                    }
                    </Button>
                    {wrongPassword &&
                        <div className='flex items-center'>
                            <p className='text-red-500'>Password field should not be empty.</p>
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8 text-red-500" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <path className="animate-x" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </div>
                    }
                    {wrongPhoneNumber &&
                        <div className='flex items-center'>
                            <p className='text-red-500 text-xs md:text-sm'>Please input a valid email or phone number(eg 070312456789 - should be 11 digits and starts with zero 0)</p>
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8 text-red-500" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <path className="animate-x" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </div>
                    }
                    {error &&
                        <div className='flex items-center'>
                            <p className='text-red-500'>{error}</p>
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8 text-red-500" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <path className="animate-x" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </div>
                    }
                    {loginSuccess &&
                        <div className='flex items-center justify-center'>
                            <p className='text-green-500'>Successfully signed in</p>
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 text-green-500" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                <path className="animate-draw" d="M5 13l4 4L19 7" />
                            </svg>
                        </div>
                    }
                </Data>
            </Wrapper>
        </Container>
    )
};

export default SignIn;

// const Container = styled.div`
// width: 40%;
// height: 69%;
// background-color: white;
// display: flex;
// justify-content: center;
// align-items: center;
// position: relative;
// border-radius: 10px;
// box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
// z-index: 30;

// @media Screen and (max-width: 768px){
//     width: 70%;
//     padding: 10px 0;
//     /* height: 65%; */
// }
// @media Screen and (max-width: 525px){
//     width: 90%;
//     height: 90%;
//     /* padding: 10px 0; */
//     padding-top: 120px;
// }
// `;

const Container = styled.div`
  width: 40%;
  height: 69%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed; /* Make the position fixed */
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  z-index: 30;
  top: 99px;

  @media screen and (max-width: 768px) {
    width: 70%;
    padding: 10px 0;
  }
  @media screen and (max-width: 525px) {
    width: 90%;
    height: 87%;
    padding-top: 52px;
    top: 59px;
  }
  /* @media screen and (max-width: 525px) {
    width: 90%;
    height: 90%;
    padding-top: 120px;
  } */
`;

const Wrapper = styled.div`
width: 95%;
height: 95%;
position: absolute;
display: flex;
flex-direction: column;
align-items: center;
`;

const CancelHold = styled.button`
width: 100%;
display: flex;
justify-content: flex-end;
color: #6c3c6c;
font-size: 30px;
font-weight: 500;
cursor: pointer;
border: none;
background: none;
`;

const Registration = styled.div`
width: 95%;
display: flex;
justify-content: center;
font-size: 25px;
color: #424242;
font-weight: 400;

`;

const Data = styled.div`
width: 90%;
height: 80%;
display: flex;
flex-direction: column;
margin-top: 20px;
`;
const InputHold = styled.div`
width: 100%;
height: 40px;
display: flex;
justify-content: space-between;
padding: 5px;
margin: 10px 0;
align-items: center;
border: 1px solid grey;
border-radius: 5px;
`;
const Input = styled.input`
width: 90%;
border: none;
outline: none;


::placeholder{
    color: #424242;
}

`;
const ResetPassword = styled.div`
width: 100%;
display :flex;
justify-content: flex-end;
align-items: center;
color: #6c3c6c;
cursor: pointer;
font-weight: 500;
font-size: 15px;
text-decoration: none;
`;

const Button = styled.button`
border: none;
width: 100%;
height: 30px;
padding: 25px 10px;
border-radius: 5px;
background-color: #6c3c6c;
color: white;
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
margin: 10px 0;
font-size: 25px;
transition: background-color 0.3s;

&:hover {
    background-color: #633b63;
  }
`;


