import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom'
// import { AiOutlineScissor } from 'react-icons/ai';
import { Puff, ThreeDots } from 'react-loading-icons';
import styled from 'styled-components';
import axios from 'axios';
import { useSelector } from 'react-redux';
import '../SettingsPage/Settings.css';
import painterImage from '../Assets/painter.jpg'
import gardnerImage from '../Assets/gardner.jpg'
import bakersImage from '../Assets/Bakers.jpg';
import makeUpArtistImage from '../Assets/make-up-artist.jpg';
import eventPlannersImage from '../Assets/Event_planners.jpg';
import fashionDesignerImage from '../Assets/fashion_design.jpg';
import dryCleaningImage from '../Assets/dry_cleaning.jpg'
import veterinaryImage from '../Assets/veterinary.jpg';
import printingPressImage from '../Assets/printing_press.jpg';
import mechanicImage from '../Assets/mechanic.jpg';
import cateringImage from '../Assets/catering.jpg';
import chefImage from '../Assets/chef.jpg';
import coverLetterImage from '../Assets/cover_letter.png';
import carWashImage from '../Assets/car_wash.jpg';
import tutorImage from '../Assets/tutor.jpg';
import privateInvestigatorImage from '../Assets/private_investigator.jpg';
import djImage from '../Assets/DJ.jpg';

import SignIn from '../Auth/SignIn';


const Hero2 = () => {

    const [getUploads, setGetUploads ] = useState()
    const [getUploadsLoading, setGetUploadsLoading ] = useState(false)
    const [getCategoriesLoading, setGetCategoriesLoading ] = useState(false)
    const [getUsers, setgetUsers ] = useState()
    const [getCategories, setGetCategories ] = useState([])
    const [getCategoriesId, setGetCategoriesId ] = useState("611f1537b48a1d3a6cb38d51")
    const [getCategoryName, setGetCategoryName ] = useState();
    const [getAdverts, setGetAdverts] = useState([]);
    const [openSignInComponent, setOpenSignInComponent ] = useState(false)

    const getUserInfoFromLocalStorage = JSON.parse(localStorage.getItem('loginInfo')) || {};

    const navigate = useNavigate ();

    const {
        success,
      } = useSelector((state) => state.searchReducer)

    const handleClick = (value) => {
        navigate('/detailPage', { state: { key: value } });
    };

    const handleGetAllUploads = async () => {
        setGetUploadsLoading(true)
        const config = {
            headers: {
              'ngrok-skip-browser-warning': 'true', //Remove this when you use the hosted server
              'Content-Type': 'application/json',
            },
          };
    
        let getAllUploads = await axios.get('https://hanaka-backend.onrender.com/api/getAllUploads', config);
        setGetUploads(getAllUploads?.data?.data)
        setGetUploadsLoading(false);
    };
    
    const handleGetAllUsers = async () => {
        const config = {
            headers: {
              'ngrok-skip-browser-warning': 'true', //Remove this when you use the hosted server
              'Content-Type': 'application/json',
            },
          };
    
        let getAllUsers = await axios.get('https://hanaka-backend.onrender.com/api/getAllUsersByVisiting', config);
        setgetUsers(getAllUsers?.data?.data)
    };
    
    const handleGetAllCategories = async () => {
        setGetCategoriesLoading(true)
        const config = {
            headers: {
              'ngrok-skip-browser-warning': 'true', //Remove this when you use the hosted server
              'Content-Type': 'application/json',
            },
          };
    
        let getAllCategories = await axios.get('https://hanaka-backend.onrender.com/api/', config);
        setGetCategories(getAllCategories?.data?.data)
        setGetCategoriesLoading(false);
    };

    const handleGetAdverts = async() => {
        const config = {
            headers: {
              'ngrok-skip-browser-warning': 'true', //Remove this when you use the hosted server
              'Content-Type': 'application/json',
            },
          };
    
        let getAllUploads = await axios.get('https://hanaka-backend.onrender.com/api/advert', config);
        setGetAdverts(getAllUploads?.data?.data);
    };

    useEffect(() => {
        handleGetAllUploads()
        handleGetAllUsers()
        handleGetAllCategories()
        handleGetAdverts()
    },[]);
    
    const getUserNameFromUserId = (uploadId) => {
        const user = getUsers?.find((user) => user?.upload === uploadId);
        return user ? user.userName : 'User Name';
    };

    const handleDelete = async (id) => {
        const config = {
            headers: {
              'ngrok-skip-browser-warning': 'true', //Remove this when you use the hosted server
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getUserInfoFromLocalStorage?.token}`,
            },
        };
    
        await axios.delete(`https://hanaka-backend.onrender.com/api/advert/${id}`, config);
        // setGetAdverts(getAllUploads?.data?.data); Add a state to tell user that it is successfully deleted
    }

    const handleCategoryClick = (catId, catName) => {
        setGetCategoriesId(catId); 
        setGetCategoryName(catName)
        navigate('/all-users', { state: { key: catId } });
    }

    const matchingUploads = getUploads?.filter(upload => upload.category === getCategoriesId);

    const categoryIcons = {
        Painter: painterImage,
        Mechanic: mechanicImage,
        'Event planners': eventPlannersImage,
        Bakers: bakersImage,
        Gardener: gardnerImage,
        'Makeup artist': makeUpArtistImage,
        'Fashion designer': fashionDesignerImage,
        'Dry cleaning': dryCleaningImage,
        Veterinary: veterinaryImage,
        'Printing press': printingPressImage,
        Catering: cateringImage,
        'Professional chef': chefImage,
        'Cover letter/ resume' : coverLetterImage,
        'Car wash' : carWashImage,
        'Personal tutor' : tutorImage,
        'Private investigator' : privateInvestigatorImage,
        'Disk jockey (DJ)' : djImage,
    };

    const handleToggleSignIn = () => {
        setOpenSignInComponent(!openSignInComponent)
    }

    const handleNavigateToSettingsComponent = (value) => {
        navigate('/upload', { state: { key: value } });
    };


  return (
    <Container>
        <Wrapper>
            <Left>
            {getCategories.length > 0 &&
                <Link to="/all-categories" className="categoryButton font-bold py-2 px-4 rounded w-5/12 md:w-1/2 text-center hidden md:flex mb-4">
                    View all categories
                </Link>
            }
                <CategoryCard>
                    {
                        getCategoriesLoading ?
                        <div className='flex items-center justify-center flex-col'>
                            <ThreeDots stroke='black'/>
                            <p>Please Wait...</p>
                        </div>
                        :
                        getCategories?.map((category, index) => {
                            const categoryName = category.categoryName?.trim();
                            const IconComponent = categoryIcons[categoryName];
                            if (IconComponent) {
                                return (
                                    <Wrap className='cursor-pointer' key={index} onClick={() => handleCategoryClick(category._id, category.categoryName)}>
                                        <img src={IconComponent} alt={"Category avatar"} style={{'width': '25%', 'background' : 'white'}} loading="lazy"/>
                                        <Span>{category.categoryName}</Span>
                                    </Wrap>
                                )
                            } else if(categoryName === 'Artisans') {
                                return (
                                    <Wrap className='cursor-pointer' key={index} onClick={() => getUserInfoFromLocalStorage?.user?.upload ? handleNavigateToSettingsComponent() : handleToggleSignIn()}>
                                    {/* <IconComponent/> */}
                                    <Span>{getUserInfoFromLocalStorage?.user?.upload ? "Edit your service" : "Post your service"}</Span>
                                </Wrap>
                                )
                            } else return null;
                        }
                    )
                }
                </CategoryCard>
                    {
                        getAdverts.map((advert) =>
                        <News key={advert._id}>
                            <Image src={advert.advertImage} alt="Advert avater"/>
                            <NewsText>
                            <Headline>{advert?.advertHeadline}</Headline>
                            <NewsBody>{advert.advertInformation}</NewsBody>
                            </NewsText>
                            {
                                getUserInfoFromLocalStorage?.user?.isAdmin &&
                            <Button onClick={() => handleDelete(advert._id)}>
                                Delete Advert
                            </Button>
                            }
                        </News>
                        )
                    }
            </Left>
            <Right>
            {getCategories.length > 0 &&
                <Link to="/all-categories" className="categoryButton font-bold py-2 px-4 rounded w-5/12 md:w-1/2 text-center md:hidden mb-5">
                    View all categories
                </Link>
            }
                <Title className='hidden md:flex'>{ getCategoryName ? getCategoryName : "Trending Ads"}</Title>
                <Title className='flex md:hidden'>{`Categories`}</Title>
                <CategoryCardScreen>
                {
                        getCategoriesLoading ?
                        <div className='flex items-center justify-center flex-col'>
                            <ThreeDots stroke='black'/>
                            <p>Hold on while we fetch categories...</p>
                        </div>
                        :
                        getCategories?.map((category, index) => {
                            const categoryName = category.categoryName?.trim();
                            const IconComponent = categoryIcons[categoryName];

                            if (IconComponent) {
                                return (
                                    <Wrap className='cursor-pointer' key={index} onClick={() => handleCategoryClick(category._id, category.categoryName)}>
                                        <img src={IconComponent} alt={"Category avatar"} style={{'width': '85%', 'background' : 'white'}} loading="lazy"/>
                                        <Span>{category.categoryName}</Span>
                                    </Wrap>
                                )
                            } else if(categoryName === 'Artisans') {
                                return (
                                    <Wrap className='cursor-pointer' key={index} onClick={() => getUserInfoFromLocalStorage?.user?.upload ? handleNavigateToSettingsComponent() : handleToggleSignIn()}>
                                    <Span>{getUserInfoFromLocalStorage?.user?.upload ? "Edit your service" : "Post your service"}</Span>
                                </Wrap>
                                )
                            } else return null;
                        }
                    )
                }
                </CategoryCardScreen>
                <Title className='flex md:hidden'>{ getCategoryName ? getCategoryName : "Trending Ads"}</Title>
                <Hold>
                    {
                        success?.length > 0 && Array.isArray(success) ? 
                        success.map((uploads, index) => {
                            return (
                                <Card key={index} onClick={() => handleClick(uploads._id)}>
                                    <Img src={uploads.images[0]} alt="vendor profile" loading="lazy"/>
                                    <Name>
                                        {getUserNameFromUserId(uploads._id)}
                                    </Name>
                                    <Job>{uploads.occupation}</Job>
                                </Card>
                               )
                        })
                        :(
                            getUploadsLoading ?
                            <div className=" flex items-center justify-center ">
                            <Puff stroke="black" className='w-40 h-52'/>
                            <Puff stroke="black" className='w-40 h-52'/>
                            <Puff stroke="black" className='hidden md:flex md:w-40 md:h-52'/>
                            <Puff stroke="black" className='hidden md:flex md:w-40 md:h-52'/>
                            <Puff stroke="black" className='hidden xl:flex md:w-40 md:h-52'/>
                          </div>
                          :
                        matchingUploads && matchingUploads.length > 0 ? (
                            matchingUploads.map(upload => (
                              <Card key={upload._id} onClick={() => handleClick(upload._id)}>
                                <Img src={upload.images[0]} alt="vendor profile" loading="lazy" />
                                <Name>{getUserNameFromUserId(upload._id)}</Name>
                                <Job>{upload.occupation}</Job>
                              </Card>
                            ))
                          ) : (
                            <p className='text-lg text-red-500 md:mt-14 md:text-3xl'>No vendor For this category</p>
                          )
                        )
                    }       
                </Hold>
                {
                    openSignInComponent &&  <SignIn setSignin={() => handleToggleSignIn()}/>
                }
                {/* <SignIn /> */}
            </Right>
        </Wrapper>
    </Container>
  )
}

export default Hero2;

const Title = styled.div`
font-size: 25px;
font-weight: 600;
color: #424242;
/* margin-top: 5px; */
`;

const Card = styled.div`
width: 25%;
height: 245px;
background-color: white;
box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
border-radius: 5px;
padding: 15px 0 0 0;
display: flex;
flex-direction: column;
align-items: center;
text-align: center;
margin: 10px 0;

&:hover {
    cursor: pointer;
  }

@media Screen and (max-width: 768px){
    width: 45%;
    margin: 10px;
}

@media Screen and (max-width: 525px){
    width: 43%;
    height: 220px;
}

`;
const Img = styled.img`
width: 150px;
height: 150px;
border-radius: 100%;
`;
const Name = styled.div`
font-size: 17px;
font-weight: 500;
color: #424242;
margin: 10px 0;
`;
const Job = styled.div`
color: orange;
margin: 0 0 5px 0;
font-weight: 500;
font-size: 14px;
`;
// const Description = styled.div`
// color: grey;
// font-size: 13px;
// margin: 5px 0 15px 0;
// width: 80%;
// `;
const Button = styled.button`
  width: 100%;
  height: 60px;
  background-color: #6c3c6c;
  color: white;
  font-size: 12px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #633b63;
  }
`;

const CategoryCard = styled.div`
width: 60%;
height: auto;
/* background-color: white; */
border-radius: 5px;
display: flex;
flex-direction: column;
justify-content: space-between;
padding: 20px 0;
/* align-items: center; */

@media Screen and (max-width: 768px){
    width: 90%;
    background:none;
    display: none;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

@media Screen and (max-width: 525px){
    /* background-color: pink; */
    width: 100%;
    display: none;
}
`;

const CategoryCardScreen = styled.div`
width: 60%;
height: auto;
/* background-color: white; */
border-radius: 5px;
display: none;
flex-direction: column;
justify-content: space-between;
padding: 20px 0;
/* align-items: center; */

@media Screen and (max-width: 768px){
    width: 90%;
    background:none;
    display: flex;

    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

@media Screen and (max-width: 525px){
    /* background-color: pink; */
    width: 100%;
    display: flex;
}
`;


const NewsText = styled.div`


@media Screen and (max-width: 768px){
    width: 50%;
}
@media Screen and (max-width: 525px){
    width: 100%;
}
`;

const News = styled.div`
margin-top: 50px;
width: 60%;
height: 400px;
background-color: white;
/* box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset; */
box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
border-radius: 5px;
padding: 40px 10px 10px 10px;
display: flex;
flex-direction: column;
align-items: center;
/* justify-content: center; */
text-align: center;
/* font-family: poppins; */

a{
    font-size: 14px;
    color: red;
}

@media Screen and (max-width: 768px){
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    height: auto;
    align-items: center;
    padding: 0;
}

@media Screen and (max-width: 525px){
    flex-direction:column;
    align-items: center;
/* justify-content: center; */
    text-align: center;
    padding: 0 0 10px 0;

}
`;
const Image = styled.img`
width: 90%;
height: 100%;
object-fit: cover;

@media Screen and (max-width: 768px){
    width: 50%;
}
@media Screen and (max-width: 525px){
    width: 100%;
}
`;
const Headline = styled.div`
color: red;
margin: 3px 0;

`;
const NewsBody = styled.div`
font-size: 13px;
width: 90%;


`;
const Wrap = styled.div`
margin: 3px 0;
width: 90%;
display: flex;
justify-content: left;
align-items: center;
font-size: 20px;
padding: 5px 20px;
background-color: white;

@media Screen and (max-width: 768px){
    width: 24%;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    height: 110px;
    border-radius: 5px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media Screen and (max-width: 525px){
    width: 32%;
}
@media Screen and (max-width: 320px){
    width: 100%;
}

`;
const Span = styled.div`
margin-left: 5px;
/* font-family: poppins, sans-serif; */
font-size: 13px;
font-weight: 700;
`;


const Container = styled.div`
width: 100%;
height: max-content;
background-color: whitesmoke;
display: flex;
justify-content: center;
align-items: center;
`;
const Wrapper = styled.div`
width: 90%;
height: 90%;
display: flex;
justify-content: space-between;
/* align-items: center; */
padding: 40px 0;

@media Screen and (max-width: 768px){
    flex-direction: column-reverse;
    padding: 0px 0;
}
`;
const Left = styled.div`
width: 30%;
height: auto;
/* background-color: red; */
display: flex;
flex-direction: column;

@media Screen and (max-width: 768px){
    width: 100%;
}
`;
const Right = styled.div`
width: 70%;
height: auto;
/* background-color: green; */
display: flex;
flex-direction: column;
@media Screen and (max-width: 768px){
    margin-top: 60px;
    width: 100%;
}
`;
const Hold = styled.div`
width: 100%;
height: auto;
/* background-color: green; */
display: flex;
justify-content: space-between;
flex-wrap: wrap;
align-items: center;

`;