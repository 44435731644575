import React, { useState, } from 'react'
import { useNavigate } from 'react-router-dom';
import styled from "styled-components"
import { FaTimes, FaBars } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';

import logo from "../Assets/logo.png"
import SignIn from "../Auth/SignIn"; 
import Register from "../Auth/Register";
import LogOutModal from '../SettingsPage/LogOutModal';
import { setVerifyModal } from '../Redux/Verify';

const Header = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [toggle, setToggle] = useState(false);
  const [signin, setSignin] = useState(false);
  const [register, setRegister] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);

  const handleToggle = () =>{
    setToggle(!toggle)
  };

  const {
    verifySuccess,
  } = useSelector((state) => state.verifyReducer)

  // useEffect(() => {
  //   if (verifySuccess) {
  //     setToggle(false)
  //   }
  //   // eslint-disable-next-line
  // }, [verifySuccess]);

  const getUserInfoFromLocalStorage = JSON.parse(localStorage.getItem('loginInfo')) || {};

  return (
    <>
    <Container>
        <Wrapper>
            <LogoWrap className='hover:cursor-pointer' onClick={() => { navigate('/'); setToggle(false) }}>
                <Logo src={logo} alt='logo'/>
                <Title>Barter</Title>
            </LogoWrap>
            <NavWrap toggle={toggle} getuserinfofromlocalstorage={getUserInfoFromLocalStorage}>
            {getUserInfoFromLocalStorage?.token ?
              <>
                <Span onClick={() => navigate('/upload')}>{getUserInfoFromLocalStorage?.user?.upload ? "Edit service" : "Post your service"}</Span>
                {/* <div>|</div> */}
                {
                (getUserInfoFromLocalStorage?.user?.isVerified === false && verifySuccess?.length === 0) &&
                <><div>|</div>
                  <Span onClick={() => dispatch(setVerifyModal())}>Verify Account</Span>
                </>
                }
                {
                  getUserInfoFromLocalStorage?.user?.isAdmin &&
                  <><div>|</div>
                    <Span onClick={() => navigate('/category')}>Category</Span>
                  </>
                }
                {
                  getUserInfoFromLocalStorage?.user?.isAdmin &&
                  <><div>|</div>
                    <Span onClick={() => navigate('/advert')}>Advert</Span>
                  </>
                }
                <div>|</div>
                <Span onClick={() => navigate('/settings')}>Settings</Span>
                <div>|</div>
                <Span onClick={() => setLogoutModal(!logoutModal)}>Log out</Span>
              </>
                  :
              <>
                <Span onClick={() =>{
                  setSignin(true);
                }}>
                  Sign In
                </Span> 
                <div>|</div>
                <Span onClick={() =>{
                  setRegister(true)
                }}>
                  Register
                </Span>
            </>
            }
            </NavWrap>
            <BurgerWrap onClick={handleToggle}  >
              {toggle? (<FaTimes/>) : (<FaBars/>)}
            </BurgerWrap>
        </Wrapper>
    </Container>
     {toggle && getUserInfoFromLocalStorage?.token ?
     (
      <AnimatedMobileNavForRegisteredUsers>
        <Span onClick={() => {navigate('/upload'); handleToggle()}}>{getUserInfoFromLocalStorage?.user?.upload ? "Edit service" : "Post your service"}</Span>
        <Span onClick={() => {navigate('/settings'); handleToggle()}}>Settings</Span>
        {
          (getUserInfoFromLocalStorage?.user?.isVerified === false || verifySuccess.length !== 0) &&
        <Span onClick={() => {dispatch(setVerifyModal()); handleToggle() }}>Verify Account</Span>
        }
        {
          getUserInfoFromLocalStorage?.user?.isAdmin &&
          <Span onClick={() => { navigate('/category'); handleToggle(); } }>Category</Span>
        }
        {
          getUserInfoFromLocalStorage?.user?.isAdmin &&
          <Span onClick={() => { navigate('/advert'); handleToggle(); } }>Advert</Span>
        }
        <Span onClick={() => {setLogoutModal(!logoutModal); handleToggle() }}>Logout</Span>
      </AnimatedMobileNavForRegisteredUsers>
    )
    :
    toggle && getUserInfoFromLocalStorage?.token === undefined ?
    (
      <AnimatedMobileNavForNewUsers>
          <span onClick={() =>{
              setSignin(true);
              handleToggle()
          }}>
            Sign In
          </span> 
          <span onClick={() =>{
            setRegister(true)
            handleToggle()
          }}>
            Register
          </span>
      </AnimatedMobileNavForNewUsers>
    )
    :
    null
    }

    {
      logoutModal && <LogOutModal closePopUp={() => setLogoutModal(!logoutModal)}/>
    }

    {signin? (<ModalDiv>
      <SignIn setSignin={setSignin}/>
      </ModalDiv>): null}
    
    {register? (<ModalDiv>
      <Register setRegister={setRegister}/>
    </ModalDiv>): null}
    </>
  )
}

export default Header;

const ModalDiv = styled.div`
width: 100%;
height: 100%;
backdrop-filter: blur(7px);
display: flex;
justify-content: center;
align-items:center;
position: fixed;
top:0;
z-index: 5;
`;

const MobileNavForNewUsers = styled.div`
  position: fixed;
  width: 182px;
  height: 220px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px;
  justify-content: space-around;
  left: ${({ toggle }) => (toggle ? 0 : '60%')};
  top: ${({toggle}) => (toggle? 0 : "13%")};
  z-index: 40;

   span {
    font-size: 25px;
    font-weight: 600;
  }

  @media screen and (max-width: 400px) {
    left: ${({ toggle }) => (toggle ? 0 : '48%')};
  }

  @media screen and (max-width: 320px) {
    left: ${({ toggle }) => (toggle ? 0 : '43%')};
  }
`;

// Add a CSS animation
const slideAnimation = (isVisible) => `
  animation: ${isVisible ? 'slideIn 0.9s ease-in-out forwards' : 'slideOut 0.9s ease-in-out forwards'};
  @keyframes slideIn {
    from {
      left: 100%;
    }
    to {
      left: 0;
    }
  }
  @keyframes slideOut {
    from {
      left: 0;
    }
    to {
      left: 50%;
  }
`;

// Apply the animation based on the 'toggle' prop
const AnimatedMobileNavForNewUsers = styled(MobileNavForNewUsers)`
  ${({ toggle }) => slideAnimation(toggle)}
`;

const MobileNav = styled.div`
  position: fixed;
  z-index: 10;
  width: 182px;
  height: 260px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  justify-content: space-around;
  left: ${({toggle}) => (toggle? 0 : "60%")};
  top: ${({toggle}) => (toggle? 0 : "13%")};

  transition: all ease 1s;
  @media Screen and (max-width: 400px){
  left: ${({toggle}) => (toggle? 0 : "50%")};
  /* top: ${({toggle}) => (toggle? 0 : "12%")}; */
  }
  @media Screen and (max-width: 320px){
  left: ${({toggle}) => (toggle? 0 : "43%")};
  }
`;

const AnimatedMobileNavForRegisteredUsers = styled(MobileNav)`
  ${({ toggle }) => slideAnimation(toggle)}
`;

const BurgerWrap = styled.div`
display: none;
z-index: 2;
right: 30px;
font-size: 30px;
cursor: pointer;
color: white;

@media Screen and (max-width: 525px){
  display: block;
  position: absolute;

}
`;

const Container = styled.div`
width: 100%;
height: 12vh;
display: flex;
justify-content: center;
align-items: center;
background-color: #6c3c6c;
box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
position: fixed;
z-index: 20;
`;

const Wrapper = styled.div`
width: 90%;
height: 90%;
display: flex;
justify-content: space-between;
align-items: center;

@media Screen and (max-width: 768px){
  width: 70%;
}
@media Screen and (max-width: 525px){
  width: 70%;
}
`;
const LogoWrap = styled.div`
width: 8%;
height: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`;

const Logo = styled.img`
width: 50px;
`;

const Title = styled.div`
color: white;
font-size: 20px;
font-weight: bold;
`;

const NavWrap = styled.div`
${({ getuserinfofromlocalstorage }) =>
    getuserinfofromlocalstorage?.token ?
    `
    width: 25%;
    `
    :
    `width: 14%;`
  }
height: 100%;
display: flex;
justify-content:  space-between;
align-items: center;
color: white;

@media Screen and (max-width: 1024px){
  width: 30%;
}
@media Screen and (max-width: 768px){
  ${({ getuserinfofromlocalstorage }) =>
    getuserinfofromlocalstorage?.token ?
    `
    width: 50%;
    `
    :
    `width: 32%;`
  }
}

@media Screen and (max-width: 525px){
  display: none;
}
`;

const Span = styled.div`
font-weight: 500;
cursor: pointer;
font-size: 17px;
/* margin-bottom: 5px; */
@media Screen and (min-width: 768px){
  font-size: 13px;
}
`;