import axios from 'axios'
import { setLoadingData, setDataError, setSuccess, setSuccessTimeOut, setErrorTimeOut } from './Login'


export const postLoginUser = (userDetail) => (dispatch) => {
  dispatch(setLoadingData())
  const config = {
    headers: {
      'ngrok-skip-browser-warning': 'true', //Remove this when you use the hosted server
      'Content-Type': 'application/json',
    },
  };

  axios
    .post(`https://hanaka-backend.onrender.com/api/login`, userDetail, config)
    .then((response) => {
        let userInfo = {
            token: response?.data?.token,
            user: response?.data?.data,
            loggedIn: true,
        }
        dispatch(setSuccess())
        setTimeout(() => {
            dispatch(setSuccessTimeOut());
          }, 5000);
      localStorage.setItem('loginInfo', JSON.stringify(userInfo));
    })
    .catch((error) => {
      dispatch(setDataError(error.response.data.message))
      setTimeout(() => {
        dispatch(setErrorTimeOut());
      }, 5000);
    })
}
