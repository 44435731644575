export const ActionTypes = {
    FETCH_DATA_LOADING: 'FETCH_DATA_LOADING',
    FETCH_DATA_ERROR: 'FETCH_DATA_ERROR',
    SUCCESS: 'SUCCESS',
    SUCCESS_TIMEOUT: 'SUCCESS_TIMEOUT',
    ERROR_TIMEOUT: 'ERROR_TIMEOUT',
  }
  
  export const setLoadingData = () => ({
    type: ActionTypes.FETCH_DATA_LOADING,
  })
  
  export const setDataError = (data) => ({
    type: ActionTypes.FETCH_DATA_ERROR,
    payload: data,
  })

  export const setSuccess = () => ({
    type: ActionTypes.SUCCESS,
  })
  export const setSuccessTimeOut = () => ({
    type: ActionTypes.SUCCESS_TIMEOUT,
  })

  export const setErrorTimeOut = () => ({
    type: ActionTypes.ERROR_TIMEOUT,
  })

  const initialState = {
    loading: false,
    error: '',
    loginSuccess: false,
  }
  
  const logInReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case ActionTypes.FETCH_DATA_LOADING:
        return {
          ...state,
          loading: true,
        }
      case ActionTypes.FETCH_DATA_ERROR:
        return {
          ...state,
          error: payload,
          loading: false,
          loginSuccess: false,
        }
      case ActionTypes.SUCCESS:
        return {
          ...state,
          loginSuccess: true,
          loading: false,
          error: '',
        }
        case ActionTypes.SUCCESS_TIMEOUT:
            return {
              ...state,
              loginSuccess: false,
        }
        case ActionTypes.ERROR_TIMEOUT:
          return {
            ...state,
            error: '',
      }
      default:
        return state
    }
  }
  export default logInReducer