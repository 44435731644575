import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../SettingsPage/Settings.css'
import { CirclesWithBar } from 'react-loader-spinner';
import { useNavigate } from "react-router-dom";
import AllCategories from './AllCategories';

function Category() {
  const [isDataSuccess, setIsDataSuccess] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [viewAllCategories, setViewAllCategories] = useState(false);
  const [noAddedCategory, setNoAddedCategory] = useState(false);
  const [categoryState, setCategoryState] = useState('');
  const [category, setCategory] = useState('');

  const navigate = useNavigate()

  const getUserInfoFromLocalStorage = JSON.parse(localStorage.getItem('loginInfo')) || {};

  const handleUpload = async (id) => {
    if(category.length === 0) {
      setNoAddedCategory(true);
      setTimeout(() => setNoAddedCategory(false), 5000);
      return;
    }
    // Upload logic here (e.g., API call)
    setIsDataLoading(true);
    let categoryUpload = {
        categoryName: category
    }

    const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getUserInfoFromLocalStorage?.token}`,
        },
      };

      await (id ? axios.patch(`https://hanaka-backend.onrender.com/api/${id}`, categoryUpload, config)
      : axios.post('https://hanaka-backend.onrender.com/api/', categoryUpload, config))
        .then(response => {
          setIsDataSuccess(true);
          setTimeout(() => setIsDataSuccess(false), 5000);
          setIsDataLoading(false);
        })
        .catch(error => {
          // console.log(error)
          setIsError(true);
          setTimeout(() => setIsError(false), 5000);
          setIsDataLoading(false);
        });
        setTimeout(() => setCategory(""), 5000);
  };

  useEffect(() => {
    if(getUserInfoFromLocalStorage?.token === undefined && getUserInfoFromLocalStorage?.user?.isAdmin) {
      return navigate('/');
    }
    window.scrollTo(0, 0);
 // eslint-disable-next-line
},[]);

useEffect(() => {
  if (categoryState.categoryName) {
      setCategory(categoryState.categoryName);
  }
}, [categoryState.categoryName]);

  return (
    <div className="min-h-screen flex justify-center items-center categoryBackground">
      <div className="p-6 bg-white rounded shadow-lg ">
        <h1 className="text-xl mb-4">Upload Category</h1>
        <div className="mb-4">
          <label className="block mb-2">Category Name:</label>
          <input 
            type="text" 
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            className="w-full p-2 border rounded focus:outline-none" 
          />
        </div>
        {
          categoryState ?
              <button
                className={`categoryButton text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-28`}
                type="submit"
                disabled={isDataLoading}
                onClick={() => handleUpload(categoryState._id)}
              >
              {isDataLoading ? (
                <CirclesWithBar
                  height="25"
                  width="100"
                  color="#0b1f3f"
                  wrapperStyle={{}}
                  visible={true}
                  outerCircleColor=""
                  innerCircleColor=""
                  barColor=""
                  ariaLabel='circles-with-bar-loading'
                />
              ) :
              "Edit Category"
            }
            </button>
            :
            <button
              className={`categoryButton text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-28`}
              type="submit"
              disabled={isDataLoading}
              onClick={() => handleUpload()}
            >
              {isDataLoading ? (
                <CirclesWithBar
                  height="25"
                  width="100"
                  color="#0b1f3f"
                  wrapperStyle={{}}
                  visible={true}
                  outerCircleColor=""
                  innerCircleColor=""
                  barColor=""
                  ariaLabel='circles-with-bar-loading'
                />
              ) :
              "Upload"
              }
        </button>
        }
        {isDataSuccess &&
          <div className='flex items-center'>
            <p className='text-green-500'> Category {categoryState ? 'Edited' : 'Uploaded'} successfully</p>
            <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8 text-green-500" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path className="animate-draw" d="M5 13l4 4L19 7" />
            </svg>
          </div>
        }
        {isError &&
          <div className='flex items-center'>
            <p className='text-red-500'>Error {categoryState ? 'Editing' : 'Uploading'} category. Please refresh page and try again. If it persists contact the developer.</p>
            <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8 text-red-500" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path className="animate-x" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </div>
        }
        {noAddedCategory &&
          <div className='flex items-center'>
            <p className='text-red-500'>Category input field cannot be empty!</p>
            <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8 text-red-500" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path className="animate-x" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </div>
        }
      <p className='text-sm text-amber-950 mt-3'>
          Kindly{' '}
          <button
            className="text-blue-500 hover:underline cursor-pointer focus:outline-none"
            onClick={() => setViewAllCategories(!viewAllCategories)}
          >
            Click here
          </button>{' '}
          to view all categories
        </p>
      </div>
      {
        viewAllCategories &&
        <AllCategories isOpen={viewAllCategories} onClose={() => setViewAllCategories(!viewAllCategories)} setCategoryState={setCategoryState}/>
      }
    </div>
  );
}

export default Category;
