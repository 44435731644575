import { BrowserRouter, Routes, Route, } from "react-router-dom";
import { Provider } from "react-redux";

import store from "./Redux/Store"
import LandingPage from "./Landing Page/LandingPage";
import Header from "./Header/Header";
import UploadPage from "./UploadPage/UploadPage";
import ResetPassWord from "./ResetPassWord/ResetPassWord";
import DetailPage from "./DetailPage/DetailPage";
import { Settings } from "./SettingsPage/Settings";
import AccountRecovery from "./Auth/AccountRecovery";
import Category from "./Category/Category";
import VerificationModal from "./Auth/VerifyUser";
import NewPassword from "./Auth/NewPassword";
import CreateAdvert from "./Adverts/CreateAdvert";
import ViewAllCategories from "./Category/ViewAllCategories";
import ViewAllUsers from "./UploadPage/AllUploads";
import TokenVerification from "./TokenVerification";

const App = () => {

  return(
    <Provider store={store}>
      <BrowserRouter>
      <TokenVerification />
      <Header/>
        <Routes>
          <Route path="/" element={<LandingPage/>}/>
          <Route path="/upload" element={<UploadPage/>}/>
          <Route path="/resetPassword" element={<ResetPassWord/>}/>
          <Route path="/detailPage" element={<DetailPage/>}/>
          <Route path="/settings" element={<Settings/>} />
          <Route path="/account-recovery" element={<AccountRecovery/>}/>
          <Route path="/category" element={<Category/>}/>
          <Route path="/password-reset" element={<NewPassword/>}/>
          <Route path="/advert" element={<CreateAdvert/>}/>
          <Route path="/all-categories" element={<ViewAllCategories/>}/>
          <Route path="/all-users" element={<ViewAllUsers/>}/>
        </Routes>
          <VerificationModal />
      </BrowserRouter>
    </Provider>
  )
};

export default App;