// import React, { useState, useEffect } from 'react';
// import { useLocation, useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import '../SettingsPage/Settings.css';
// // import userImage from '../DetailPage/img/pics1.jpg'

// const ViewAllUsers = () => {
//   const [users, setUsers] = useState(null);
//   const [getUsersFromDb, setGetUsersFromDb] = useState([]);
//   const [getUsersthroughLocationFromDb, setGetUsersthroughLocationFromDb] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);

//   const location = useLocation();
//   const navigate = useNavigate()

//   // const { key } = location.state;

//   useEffect(() => {
//     // Fetch the list of users from your API or data source
//     if(location.state === null) {
//         navigate('/')
//     }

//     const fetchUsers = async () => {
//       setLoading(true);
//       const config = {
//         headers: {
//           'ngrok-skip-browser-warning': 'true', // Remove this when you use the hosted server
//           'Content-Type': 'application/json',
//         },
//       };
//       try {
//         const response = await axios.get(`https://hanaka-backend.onrender.com/api/getAllUploadByCategory/${location?.state?.key}`, config);
//         setUsers(response?.data?.data);
//         let getAllUsers = await axios.get('https://hanaka-backend.onrender.com/api/getAllUsersByVisiting', config);
//         setGetUsersFromDb(getAllUsers?.data?.data)
//       } catch (error) {
//         setError(error.response.data.message);
//       }
//       setLoading(false);
//     };

//     fetchUsers();
//     // eslint-disable-next-line
//   }, []);

//   const getUsersByLocation = () => {
//     setLoading(true);
//     const config = {
//       headers: {
//         'ngrok-skip-browser-warning': 'true',
//         'Content-Type': 'application/json',
//       },
//     };

//     if ("geolocation" in navigator) {
//       navigator.geolocation.getCurrentPosition(
//         function(position) {
//           const latitude = position.coords.latitude;
//           const longitude = position.coords.longitude;
  
//           const locationDetails = {
//             latitude,
//             longitude,
//           };

//           axios
//             .post(`https://hanaka-backend.onrender.com/api/getAllUsersLocationByCategoryId/${location?.state?.key}`, locationDetails, config)
//             .then((response) => {
//               // dispatch(setSearchSuccess(response?.data?.data ? response?.data?.data : response?.data?.message));
//               // dispatch(setSuccessMessage())
//               setGetUsersthroughLocationFromDb(response?.data?.data ? response?.data?.data : response?.data?.message)
//               setLoading(false);
//               setTimeout(() => {
//                 // dispatch(setSuccessMessageTimeOut());
//               }, 4000);
//             })
//             .catch((error) => {
//               // dispatch(setDataError(error.response?.data?.message));
//               console.log(error);
//               setError(error.response.data.message);
//               setLoading(false);
//               setTimeout(() => {
//                 // dispatch(setErrorTimeOut());
//               }, 4000);
//             });
//         },
//         function(err) {
//           // Handle error, e.g., setError(err.message);
//         },
//         {
//           enableHighAccuracy: true,
//           timeout: 20000,
//           maximumAge: 60000,
//         }
//       );
//     }
//   }

//   console.log(getUsersthroughLocationFromDb)

//   if (loading) {
//     return <p className="text-center pt-32 md:pt-80 text-3xl md:text-5xl">Loading...</p>;
//   }

//   if (error) {
//     return <p className="text-center pt-24 md:pt-80 text-3xl md:text-5xl text-red-600">Error: {error}</p>;
//   }

//   const getUserNameFromUserId = (uploadId) => {
//     const user = getUsersFromDb?.find((user) => user?.upload === uploadId);
//     return user ? user.userName : 'User Name';
// };

// const handleClick = (value) => {
//   navigate('/detailPage', { state: { key: value } });
// };

//   return (
//   <div className="container mx-auto px-4 sm:px-8 md:px-12 lg:px-16 viewAllCategoriesBackground">
//     <div className='flex justify-around items-center md:justify-center md:gap-3'>
//       <p className='font-semibold	text-sm	'>Want users for your current location?</p> 
//       <button onClick={() => getUsersByLocation()} className="allUploadsButton text-white font-medium py-2 px- rounded-md">Click here</button>    
//     </div>
//     <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-4">
//     {
//     getUsersthroughLocationFromDb > 0 ?
//      getUsersthroughLocationFromDb?.map((user, index) => (
//       <div key={index} onClick={() => handleClick(user._id)} className="w-full sm:w-1/2 md:w-11/12 lg:w-9/12 pt-10 cursor-pointer">
//         <div className="border rounded-lg p-4">
//           <img src={user.images[0]} alt='users Avater' className="rounded-full w-32 h-32 object-cover"  />
//           <p className="text-xl font-semibold mb-2 ml-7">{getUserNameFromUserId(user._id)}</p>
//           <p className="text-gray-600 ml-7">{user.occupation}</p>
//           <p className="text-gray-600 ml-7 font-semibold">{user.location}</p>
//         </div>
//       </div>
//     ))
//     :
//     users?.length > 0 && users?.map((user, index) => (
//       <div key={index} onClick={() => handleClick(user._id)} className="w-full sm:w-1/2 md:w-11/12 lg:w-9/12 pt-10 cursor-pointer">
//         <div className="border rounded-lg p-4">
//           <img src={user.images[0]} alt='users Avater' className="rounded-full w-32 h-32 object-cover"  />
//           <p className="text-xl font-semibold mb-2 ml-7">{getUserNameFromUserId(user._id)}</p>
//           <p className="text-gray-600 ml-7">{user.occupation}</p>
//           <p className="text-gray-600 ml-7 font-semibold">{user.location}</p>
//         </div>
//       </div>
//     ))
    
//     }
//     </div>
//     {
//       users?.length === 0 &&
//       <p className='pt-7 text-2xl font-medium	text-red-500 md:pt-28'>No vendor found for this category</p>
//     }
//   </div>

//   );
// };

// export default ViewAllUsers;

import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../SettingsPage/Settings.css';

const ViewAllUsers = () => {
  const [users, setUsers] = useState(null);
  const [getUsersFromDb, setGetUsersFromDb] = useState([]);
  const [getUsersthroughLocationFromDb, setGetUsersthroughLocationFromDb] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state === null) {
      navigate('/');
    }

    const fetchUsers = async () => {
      setLoading(true);
      const config = {
        headers: {
          'ngrok-skip-browser-warning': 'true',
          'Content-Type': 'application/json',
        },
      };

      try {
        const response = await axios.get(
          `https://hanaka-backend.onrender.com/api/getAllUploadByCategory/${location?.state?.key}`,
          config
        );

        setUsers(response?.data?.data);

        let getAllUsers = await axios.get('https://hanaka-backend.onrender.com/api/getAllUsersByVisiting', config);
        setGetUsersFromDb(getAllUsers?.data?.data);
      } catch (error) {
        setError(error.response.data.message);
      }

      setLoading(false);
    };

    fetchUsers();
    // eslint-disable-next-line
  }, []);

  const getUsersByLocation = () => {
    setLoading(true);
    const config = {
      headers: {
        'ngrok-skip-browser-warning': 'true',
        'Content-Type': 'application/json',
      },
    };

    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;

          const locationDetails = {
            latitude,
            longitude,
          };

          axios
            .post(
              `https://hanaka-backend.onrender.com/api/getAllUsersLocationByCategoryId/${location?.state?.key}`,
              locationDetails,
              config
            )
            .then((response) => {
              setGetUsersthroughLocationFromDb(response?.data?.data ? response?.data?.data : response?.data?.message);
              setLoading(false);
              setTimeout(() => {
                // handle success timeout
              }, 4000);
            })
            .catch((error) => {
              console.log(error);
              setError(error.response.data.message);
              setLoading(false);
              setTimeout(() => {
                // handle error timeout
              }, 4000);
            });
        },
        function (err) {
          // Handle error, e.g., setError(err.message);
        },
        {
          enableHighAccuracy: true,
          timeout: 20000,
          maximumAge: 60000,
        }
      );
    }
  };


  if (loading) {
    return <p className="text-center pt-32 md:pt-80 text-3xl md:text-5xl">Loading...</p>;
  }

  if (error) {
    return <p className="text-center pt-24 md:pt-80 text-3xl md:text-5xl text-red-600">Error: {error}</p>;
  }

  const getUserNameFromUserId = (uploadId) => {
    const user = getUsersFromDb?.find((user) => user?.upload === uploadId);
    return user ? user.userName : 'User Name';
  };

  const handleClick = (value) => {
    navigate('/detailPage', { state: { key: value } });
  };

  return (
    <div className="container mx-auto px-4 sm:px-8 md:px-12 lg:px-16 viewAllCategoriesBackground">
      {
        users?.length > 0 && 
        <div className="flex justify-around items-center md:justify-center md:gap-3">
          <p className="font-semibold text-xs md:text-sm">Want vendors for your current location?</p>
          <button onClick={() => getUsersByLocation()} className="allUploadsButton text-white font-medium py-2 px- rounded-md">
            Click here
          </button>
        </div>
      }
      <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-4">
        {getUsersthroughLocationFromDb > 0 ? (
          getUsersthroughLocationFromDb?.map((user, index) => (
            <div key={index} onClick={() => handleClick(user._id)} className="w-full sm:w-1/2 md:w-11/12 lg:w-9/12 pt-10 cursor-pointer">
              <div className="border rounded-lg p-4">
                <img src={user.images[0]} alt="users Avater" className="rounded-full w-32 h-32 object-cover" />
                <p className="text-xl font-semibold mb-2 ml-7">{getUserNameFromUserId(user._id)}</p>
                <p className="text-gray-600 ml-7">{user.occupation}</p>
                <p className="text-gray-600 ml-7 font-semibold">{user.location}</p>
              </div>
            </div>
          ))
        ) 
        :
        getUsersthroughLocationFromDb?.length > 0 && getUsersthroughLocationFromDb === 'No vendor found within your location' ? (
          <p className="pt-7 text-2xl font-medium text-red-500 md:pt-28">{getUsersthroughLocationFromDb}</p>
        )
        :
         (
          users?.length > 0 &&
          users?.map((user, index) => (
            <div key={index} onClick={() => handleClick(user._id)} className="w-full sm:w-1/2 md:w-11/12 lg:w-9/12 pt-10 cursor-pointer">
              <div className="border rounded-lg p-4">
                <img src={user.images[0]} alt="users Avater" className="rounded-full w-32 h-32 object-cover" />
                <p className="text-xl font-semibold mb-2 ml-7">{getUserNameFromUserId(user._id)}</p>
                <p className="text-gray-600 ml-7">{user.occupation}</p>
                <p className="text-gray-600 ml-7 font-semibold">{user.location}</p>
              </div>
            </div>
          ))
        )}
      </div>
      {users?.length === 0 && (
        <p className="pt-7 text-2xl font-medium text-red-500 md:pt-28">No vendor found for this category</p>
      )}
      {/* {getUsersthroughLocationFromDb?.length === 0 && getUsersthroughLocationFromDb === 'No Vendor found within your location' && (
        <p className="pt-7 text-2xl font-medium text-red-500 md:pt-28">No vendor found for this category</p>
      )
      } */}
    </div>
  );
};

export default ViewAllUsers;
