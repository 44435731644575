import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Settings.css';

const LogOutModal = ({ closePopUp }) => {
  const [isVisible, setIsVisible] = useState(false);
  const navigate = useNavigate()

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        closePopUp();
      }
    };
    window.addEventListener('keydown', handleKeyDown);

    setTimeout(() => {
      setIsVisible(true);
    }, 10);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
    // eslint-disable-next-line
  }, []);

  const exitApp = () => {
    // TODO: Make API calls to create sub-users then close the modal.
    closePopUp();
  };

  const closeOnOverlayClick = (e) => {
    if (e.target.classList.contains('items-center')) {
      closePopUp();
    }
  };

  const handleLogOut = (e) => {
    localStorage.removeItem('loginInfo'); 
    exitApp()
    navigate('/')
  }

  return (
    <div onClick={ closeOnOverlayClick } className={`fixed inset-0 flex items-center justify-center z-20`}>
      <div className={`fixed inset-0 bg-gray-800 opacity-50 transition-opacity ${isVisible ? 'opacity-100' : 'opacity-0'}`}></div>
      <div className={`fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center`}>
        <div className={`flex flex-col items-center justify-center max-w-md w-full p-4 bg-white rounded-lg shadow-lg transition-opacity ${isVisible ? 'opacity-100' : 'opacity-0'} mx-4 md:mx-0`}  style={{height: "35vh"}}>
          <div className="flex justify-between">
            <h2 className="text-2xl font-semibold mb-4 text-center ">Are you sure?</h2>
          </div>
          <div className='flex w-full justify-center gap-x-3 mb-3'>
            <button className=" text-white py-2 px-4 rounded categoryButton w-1/4" onClick={() => handleLogOut()}>
                Confirm
              </button>
              <button className=" text-white py-2 px-4 rounded categoryButton transition-duration-300 w-1/4" onClick={() => exitApp()}>
                Cancel
              </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogOutModal;
