import React from "react";
import style from 'styled-components'
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
// import Swal from 'sweetalert2';

const ResetPassWord = () => {

    const validationSchema = yup.object().shape({
		email: yup.string().email().required("This field has to be filled"),
		password: yup.string().max(16).min(5).required("This field has to be filled"),
		token: yup.string().required("This field has to be filled")
	});

	const {
		register,
		reset,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(validationSchema),
	});

    const onSubmit = handleSubmit(async (data) => {
		
		console.log(data);
		const { email, password, token } = data;

		const formData = new FormData();

		formData.append("email", email);
		formData.append("password", password);
		formData.append("token", token);

		reset();
		// window.location.reload();

        // Show success message using SweetAlert2
        // showSuccessMessage();
	});

    // Sweetalert Configuratins.................

    // const showSuccessMessage = () => {
    //     Swal.fire({
    //       icon: 'success',
    //       title: 'Successful password reset',
    //       text: 'You can now use your new password to login to your account',
    //       confirmButtonText: 'Login',
    //     }).then((result) => {
    //       if (result.isConfirmed) {
            
    //         // Redirect the user to the login page here
    //         window.location.href = '/login';
    //       }
    //     });
    //   };
  return <Container>
    <Wrapper onSubmit={onSubmit} type="multipart/form-data">
        <Text>
            <Title>Reset your password</Title>
            <Description>Enter the email, new password and the code you received</Description>
        </Text>
        <InputWrap>
                <InputSection>
                    <Label>Email Address</Label>
                    <Input {...register("email")}/>
                    <div>{errors.email && errors.email.message}</div>
                </InputSection>
                <InputSection>
                    <Label>New Password</Label>
                    <Input placeholder="************" {...register("password")}/>
                    <div>{errors.password && errors.password.message}</div>
                </InputSection>
                <InputSection>
                    <Label>OTP Code</Label>
                    <Input placeholder="1234567" {...register("token")}/>
                    <div>{errors.token && errors.token.message}</div>
                </InputSection>
                <Button type="submit">Submit</Button>
        </InputWrap>
    </Wrapper>
  </Container>;
};

export default ResetPassWord;

const Container = style.div`
width: 100%;
// height: 100vh;
background: #F5F5F5;
display: flex;
align-items: center;
justify-content: center;
padding: 50px 0;
`
const Wrapper = style.form`
width: 45%;
height: 90%;
background: white;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
border-radius: 10px;
padding: 70px 0;
@media screen and (max-width: 1164px){
    width: 60%;
};
@media screen and (max-width: 764px){
    width: 70%;
};
@media screen and (max-width: 620px){
    width: 80%;
};
`
const Text = style.div`
width: 100%;
height: 20%;
// background: red;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
text-align: center;
`
const Title = style.div`
font-size: large;
font-weight: 700;
margin-bottom: 10px;
`
const Description = style.div`
width: 50%;
text-align: center;
@media screen and (max-width: 500px){
    width: 90%;
};
`
const InputWrap = style.div`
width: 100%;
height: 75%;
// background: pink;
display: flex;
flex-direction: column;
align-items: center;
// justify-content: center;
`
const InputSection = style.div`
width: 80%;
// background: red;
margin: 10px 0;
div {
    color: red;
}
`
const Label = style.label``
const Input = style.input`
width: 100%;
height: 50px;
border-radius: 5px;
border: solid #F2F2F2;
outline: none;
padding: 5px 10px;
@media screen and (max-width: 644px){
    height: 40px;
};
@media screen and (max-width: 500px){
    width: 90%;
};
@media screen and (max-width: 375px){
    height: 33px;
    width: 90%;
};
`
const Button = style.button`
width: 83%;
height: 58px;
border: none;
margin-top: 20px;
margin-left: 20px;
border-radius: 5px;
background: #9302E1;
color: white;
`
// const Container = style.div``
// const Container = style.div``
// const Container = style.div``
// const Container = style.div``
// const Container = style.div``
// const Container = style.div``
// const Container = style.div``
