import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../SettingsPage/Settings.css';

import painterImage from '../Assets/painter.jpg'
import gardnerImage from '../Assets/gardner.jpg'
import bakersImage from '../Assets/Bakers.jpg';
import makeUpArtistImage from '../Assets/make-up-artist.jpg';
import eventPlannersImage from '../Assets/Event_planners.jpg';
import fashionDesignerImage from '../Assets/fashion_design.jpg';
import dryCleaningImage from '../Assets/dry_cleaning.jpg'
import veterinaryImage from '../Assets/veterinary.jpg';
import printingPressImage from '../Assets/printing_press.jpg';
import mechanicImage from '../Assets/mechanic.jpg';
import cateringImage from '../Assets/catering.jpg';
import chefImage from '../Assets/chef.jpg';
import coverLetterImage from '../Assets/cover_letter.png';
import carWashImage from '../Assets/car_wash.jpg';
import tutorImage from '../Assets/tutor.jpg';
import privateInvestigatorImage from '../Assets/private_investigator.jpg';
import travelAgent from '../Assets/travelAgent.jpg'
import djImage from '../Assets/DJ.jpg';
import videoCoachImage from '../Assets/videoCoach.jpg';
import graphicDesignImage from '../Assets/graphicDesign.jpg';
import freightFowarderImage from '../Assets/freightFowarder.jpg';
import photographyImage from '../Assets/photography.jpg';
import driverImage from '../Assets/driver.jpg';
import officeMoversImage from '../Assets/officemovers.jpg';
import interpreterImage from '../Assets/interpreters.png';
import copywritingImage from '../Assets/copywriting.jpg';
import electricianImage from'../Assets/electrician.jpg';
import horticultureImage from '../Assets/horticulture.jpg';
import interiorDecorImage from '../Assets/interiorDecor.jpg';
import architectureImage from '../Assets/architecture.jpg';
import webDesigner from '../Assets/webDesigner.jpg';
import fitnessTrainer from '../Assets/fitnessTrainer.jpg';
import errandRunnerImage from '../Assets/errandRunner.jpg';
import unisexHairStylist from '../Assets/unisexHairstylist.jpg';
import plumberImage from '../Assets/plumber.jpg';
import stylistImage from '../Assets/stylist.jpg';
import systemInstallationImage from '../Assets/ststemInstallation.jpg';
import SEOImage from '../Assets/SEO.png';
import poolCleaningImage from '../Assets/poolCleaning.jpg';
import digitalMarkettingImage from '../Assets/digitalMarketting.jpg';
import lashImage from '../Assets/lash.jpg';
import scriptWritingImage from '../Assets/scriptWriting.jpg';
import languageTutor from '../Assets/langTutor.jpg';
import sewageRemovalImage from '../Assets/sewageRemoval.jpg';
import contentCreatorImage from '../Assets/contentCreator.jpg';
import frunitureCleaning from '../Assets/furnitureCleaning.jpg';
import securityImage from '../Assets/security.jpg';
import solarInstallerImage from '../Assets/solar.jpg';
import mcIMage from '../Assets/MC.png';
import ambulance from '../Assets/ambulance.jpg';
import bridalRentalImage from '../Assets/bridalRental.jpg';
import trainingAndConsultingServicesImage from '../Assets/training.jpg';
import buildingImage from '../Assets/building.jpg';


const ViewAllCategories = () => {
  const [getAllCategories, setGetAllCategories] = useState([]);
  const [getCategoriesLoading, setGetCategoriesLoading] = useState(false);
  const [getCategoriesError, setGetCategoriesError] = useState(null);

  const navigate = useNavigate()

  const categoryIcons = {
    Painter: painterImage,
    Mechanic: mechanicImage,
    'Event planners': eventPlannersImage,
    Bakers: bakersImage,
    Gardener: gardnerImage,
    'Makeup artist': makeUpArtistImage,
    'Fashion designer': fashionDesignerImage,
    'Dry cleaning': dryCleaningImage,
    Veterinary: veterinaryImage,
    'Printing press': printingPressImage,
    Catering: cateringImage,
    'Professional chef': chefImage,
    'Cover letter/ resume' : coverLetterImage,
    'Car wash' : carWashImage,
    'Personal tutor' : tutorImage,
    'Private investigator' : privateInvestigatorImage,
    'Disk jockey (DJ)' : djImage,
    'Travel agent' : travelAgent,
    'Voice Coach' : videoCoachImage,
    'Graphics Design' : graphicDesignImage,
    'Freight Brokerage/ Clearing Agent' : freightFowarderImage,
    'Photographer/Video graphics' : photographyImage,
    'Personal Driver' : driverImage,
    'Office Relocation/Movers' : officeMoversImage,
    'Translator/ Interpreter' : interpreterImage,
    'Copywriting/Proofreading' : copywritingImage,
    'Electrician' : electricianImage,
    'Hauticulturist' : horticultureImage,
    'Interior Decorator' : interiorDecorImage,
    'Architecture' : architectureImage,
    'Website designer' : webDesigner,
    'Personal fitness trainer' : fitnessTrainer,
    'Errand Runner/Personal Shopper' : errandRunnerImage,
    'Hairdressers/Barbers' : unisexHairStylist,
    'Plumber' : plumberImage,
    'Personal Stylist' : stylistImage,
    'Security System Installation' : systemInstallationImage,
    'Search Engine Optimization (SEO)' : SEOImage,
    'Pool Cleaning' : poolCleaningImage,
    'Digital marketer' : digitalMarkettingImage,
    'Micro blading/ Permanent lash extension' : lashImage,
    'Script writing' : scriptWritingImage,
    'Language tutor' : languageTutor,
    'Sewage removal' : sewageRemovalImage,
    'Content creator' : contentCreatorImage,
    'Upholstery cleaning' : frunitureCleaning,
    'Security/Guard services' : securityImage,
    'Solar panel installation/repair' : solarInstallerImage,
    'MC' : mcIMage,
    'Ambulance services' : ambulance,
    'Bridal rentals' : bridalRentalImage,
    'Training/Consulting services' : trainingAndConsultingServicesImage,
    'Building contractors' : buildingImage,
};
  
  const handleGetAllCategories = async () => {
    setGetCategoriesLoading(true);
    const config = {
      headers: {
        'ngrok-skip-browser-warning': 'true', // Remove this when you use the hosted server
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await axios.get('https://hanaka-backend.onrender.com/api/', config);
      setGetAllCategories(response?.data?.data);
    } catch (error) {
      console.error(error);
      setGetCategoriesError(error.response.data.message);
    }
    setGetCategoriesLoading(false);
  };

  useEffect(() => {
    handleGetAllCategories();
  }, []);

  const handleClick = (value) => {
    navigate('/all-users', { state: { key: value } });
};

if(getCategoriesLoading) {
  return <p className="text-center pt-24 md:pt-80 text-3xl md:text-5xl">Loading...</p>;
}

if (getCategoriesError) {
  return <p className="text-center pt-24 md:pt-80 text-3xl md:text-5xl text-red-600">Error: {getCategoriesError}</p>;
}

  return (
    <div className="flex flex-wrap viewAllCategoriesBackground px-1 md:px-4">
      {getAllCategories.map((category, index) => {
        const categoryName = category.categoryName?.trim();
        const IconComponent = categoryIcons[categoryName];
        if (IconComponent) {
          return (
            <div key={index} onClick={() => handleClick(category._id)} className="w-4/12 sm:w-1/3 md:w-1/4 lg:w-1/4 p-2 hover:cursor-pointer">
              <div className="border rounded-lg p-4">
              <img src={IconComponent} className='w-10/12 md:w-2/6 bg-white' alt={"Category avatar"}  loading="lazy"/>
                <p className='allCategories'>{categoryName}</p>
              </div>
            </div>
          );
        }

        if(categoryName === 'Artisans') {
          return null;
        }

        return (
          <div key={index} className="w-4/12 sm:w-1/3 md:w-1/4 lg:w-1/4 p-2">
            <div className="border rounded-lg p-4">
              <p className='allCategories'>{categoryName}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ViewAllCategories;
