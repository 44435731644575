import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import './Settings.css';
import SettingsEditForm from './SettingsEditForm';
import LogOutModal from './LogOutModal';
import HelpModal from './HelpCard';

export const Settings = () => {
    const[editDisplay, setEditDisplay] = useState(false);
    const [selectedTag, setSelectedTag] = useState(null);
    const[exitModal, setExitModal] = useState(false);
    const navigate = useNavigate()

    useEffect(() => {
        setSelectedTag("profileEdit")
    window.scrollTo(0, 0);
    },[])

    function backToHome() {
        navigate('/')
    };

  return (
    <div className='lg:flex lg:bg-gray-100 pt-24'>
       <div className='w-full lg:w-1/2'>
            <div className='hidden md:flex md:gap-x-3 md:items-center md:px-6 md:mx-9 md:mt-8 setting_display md:h-12'>
                <i className="fa fa-long-arrow-left text-white hover:cursor-pointer" onClick={() => backToHome()} aria-hidden="true"></i>
                <p className='text-base text-white'>
                    Settings
                </p>
            </div>
            <div className='flex justify-between items-center px-6 mt-8 md:mx-9'>
                <p onClick={() => setSelectedTag("profileEdit")} className={`text-base ${selectedTag === "profileEdit" ? "settings_color" : "" }`}>
                    Edit Profile
                </p>
                <i className={`fa fa-long-arrow-right ${selectedTag === "profileEdit" ? "settings_color" : "" } hover:cursor-pointer`} aria-hidden="true"  onClick={() => {
                     setEditDisplay(true)
                     setSelectedTag("profileEdit")
                }}></i>
            </div>
            <div className='flex justify-between items-center px-6 mt-8 md:mx-9'>
                <p onClick={() => setSelectedTag("profileDelete")} className={`text-base ${selectedTag === "profileDelete" ? "settings_color" : ""}`}>
                    Delete User Profile(N/A)
                </p>
                <i className={`fa fa-long-arrow-right ${selectedTag === "profileDelete" ? "settings_color" : ""} hover:cursor-pointer`} onClick={() => setSelectedTag("profileDelete")} aria-hidden="true"></i>
            </div>
            <div className='flex justify-between items-center px-6 mt-8 md:mx-9'>
                <p onClick={() => setSelectedTag("profileHelp")} className={`text-base ${selectedTag === "profileHelp" ? "settings_color" : ""}`}>
                    Help?
                </p>
                <i className={`fa fa-long-arrow-right ${selectedTag === "profileHelp" ? "settings_color" : ""} hover:cursor-pointer`} onClick={() => setSelectedTag("profileHelp")} aria-hidden="true"></i>
            </div>
            <div className='flex justify-between items-center px-6 mt-12 md:mx-9 cursor-pointer'>
                <p className='text-base text-red-500'  onClick={() => setExitModal(!exitModal)}>
                    LogOut
                </p>
            </div>
       </div>
        <div className={`bg-white transform transition-transform transition-duration-500 ${editDisplay ? 'lg:hidden' : 'translate-x-full'} h-screen fixed top-20 md:top-24 left-2 edit_display`}>
            <SettingsEditForm editFunctionProps={setEditDisplay}/>
        </div>
        <div className={`hidden lg:block w-1/2`}>
            <SettingsEditForm editFunctionProps={setEditDisplay}/>
        </div>
        {
            exitModal && <LogOutModal closePopUp={ () => setExitModal(!exitModal) }/>
        }
        {
            selectedTag === 'profileHelp' && <HelpModal selectedTag={() => setSelectedTag("profileEdit")}/>
        }
    </div>
  )
}
