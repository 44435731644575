import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { CirclesWithBar } from 'react-loader-spinner';
import '../SettingsPage/Settings.css'


const CategoryModal = ({ isOpen, onClose, setCategoryState }) => {
    const [isDataSuccess, setIsDataSuccess] = useState(false);
    const [isAllCategoryDataLoading, setisAllCategoryDataLoading] = useState(false);
    const [isError, setIsError] = useState('');
    const [getAllCategories, setGetAllCategories] = useState([]);

    const getUserInfoFromLocalStorage = JSON.parse(localStorage.getItem('loginInfo')) || {};

    const handleGetAllCategories = async () => {
        setisAllCategoryDataLoading(true);
        const config = {
            headers: {
              'Content-Type': 'application/json',
              'ngrok-skip-browser-warning': 'true',
            },
          };
    
          await axios.get('https://hanaka-backend.onrender.com/api/', config)
            .then(response => {
            //   setIsDataSuccess(true);
              setGetAllCategories(response.data.data)
              setTimeout(() => setIsDataSuccess(false), 5000);
              setisAllCategoryDataLoading(false);
            })
            .catch(error => {
              setIsError(error?.response?.data?.message);
              setTimeout(() => setIsError(''), 5000);
              setisAllCategoryDataLoading(false);
            });
      };

    const handleDeleteCategory = async (categoryId) => {
        // Handle category deletion here
        console.log(`Delete ${categoryId}`);
        const config = {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${getUserInfoFromLocalStorage.token}`,
            },
          };
        
        await axios.delete(`https://hanaka-backend.onrender.com/api/${categoryId}`, config)
        .then(response => {
          setIsDataSuccess(true);
          setGetAllCategories(response.data.data)
          setTimeout(() => setIsDataSuccess(false), 5000);
          setisAllCategoryDataLoading(false);
        })
        .catch(error => {
        //   setIsError(true);
          setIsError(error?.response?.data?.message);
          setTimeout(() => setIsError(''), 5000);
          setisAllCategoryDataLoading(false);
        });
    }

    useEffect(() => {
        handleGetAllCategories()
    },[]);

    // console.log(setCategoryState)
    const editHandler = (category) => {
      setCategoryState(category);
      onClose()
    }

  return (
    <div
      className={`fixed z-10 inset-0 overflow-y-auto ${isOpen ? 'block' : 'hidden'}`}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className={`inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full ${
            isOpen ? 'opacity-100 translate-y-0 sm:scale-100' : 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          } w-3/5`}
        >
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900"
                  id="modal-title"
                >
                  All Categories
                </h3>
                <div className="mt-2">
                  <ul className="list-inside list-disc">
                  {getAllCategories?.map((category, index) => (
                        <li key={index} className="text-gray-600 text-sm flex justify-between items-center">
                        {category.categoryName}
                        <button
                            onClick={() => handleDeleteCategory(category._id)}
                            className="text-red-500 hover:text-red-700 cursor-pointer"
                        >
                            Delete
                        </button>
                        <button
                            onClick={() => editHandler(category)}
                            className="text-red-500 hover:text-red-700 cursor-pointer"
                        >
                            Edit
                        </button>
                        </li>
                    ))}
                    {
                        isAllCategoryDataLoading 
                        &&
                        <CirclesWithBar
                            height="35"
                            width="100"
                            color="#000"
                            wrapperStyle={{"justifyContent": "center"}}
                            visible={true}
                            outerCircleColor=""
                            innerCircleColor=""
                            barColor=""
                            ariaLabel='circles-with-bar-loading'
                        />
                    }
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              onClick={onClose}
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm categoryButton"
            >
              Close
            </button>
            {isDataSuccess &&
                <div className='flex items-center'>
                    <p className='text-green-500'> Category deleted successfully</p>
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8 text-green-500" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path className="animate-draw" d="M5 13l4 4L19 7" />
                    </svg>
                </div>
            }
            {isError &&
                <div className='flex items-center'>
                    <p className='text-red-500  text-xs md:text-base'>{isError}</p>
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8 text-red-500" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <path className="animate-x" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryModal;
