import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";

const SimpleSlider = ({ prop }) => {
  return (
    <SliderContainer>
      <Slider {...settings}>
        {
          prop?.map((image, index) => 
            <Slide key={index}><img src={image} alt="Vendor work" /></Slide>
          )
        }
      </Slider>
    </SliderContainer>
  );
};

export default SimpleSlider;


const SliderContainer = styled.div`
  /* width: 400px; */
  height: 400px;
  margin: 0 auto;
`;

const Slide = styled.div`
  width: 400px;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f0f0f0;
  border: 1px solid #ccc;
  @media screen and (max-width: 824px){
    height:  500px;
};
  img {
    /* width: 100%; */
    height: auto;
    /* object-fit: cover; */
    border-radius: 5px;
    /* object-position: top; */
  }
`;

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
};
